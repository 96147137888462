import {
    CartApi,
    CartSummary,
    CartUpdateCartOrderCheckoutPOSTRequest,
    CartUpdateCartPOSTRequest,
    Checkout,
    OrderApi,
    OrderCartCheckoutWithAttestation,
    OrderGetOrderRequest,
    OrderSearchOrdersRequest,
    ShopCustomerOrderOverviewPageInfo,
} from 'microshop-api';
import { config } from './api';

const orderApi = new OrderApi(config);
const cartApi = new CartApi(config);

const order = {
    orderSearchOrders: (request: OrderSearchOrdersRequest): Promise<ShopCustomerOrderOverviewPageInfo> =>
        orderApi.orderSearchOrders(request),
    orderGetOrder: (request: OrderGetOrderRequest): Promise<OrderCartCheckoutWithAttestation> =>
        orderApi.orderGetOrder(request),
    cartUpdateCartPOST: (request: CartUpdateCartPOSTRequest): Promise<CartSummary> =>
        cartApi.cartUpdateCartPOST(request),
    cartUpdateCartOrderCheckoutPOST: (request: CartUpdateCartOrderCheckoutPOSTRequest): Promise<Checkout> =>
        cartApi.cartUpdateCartOrderCheckoutPOST(request),
};

export { order };
