import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HeaderIconButton from 'components/ui/HeaderIconButton';
import { useEffect, useState } from 'react';
import { Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownToggle } from 'reactstrap';
import styled from 'styled-components';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import authService from './AuthorizeService';
import useAppSelector from 'hooks/useAppSelector';
import { UserCard } from './UserCard';
import useAppDispatch from 'hooks/useAppDispatch';
import { populateUserInfo, selectUserInfo } from 'store/reducers/shopSlice';

const LoginMenu = ({
    loginLabel,
    logoutLabel,
    className,
}: {
    loginLabel: string;
    logoutLabel: string;
    className: string;
}) => {
    const dispatch = useAppDispatch();
    const userInfo = useAppSelector(selectUserInfo);
    const [dropDown, setDropDownOpen] = useState<boolean>(false);

    useEffect(() => {
        const subscription = authService.subscribe(() => {
            dispatch(populateUserInfo());
        });
        dispatch(populateUserInfo());

        return () => {
            authService.unsubscribe(subscription);
        };
    }, []);

    const anonymousView = (loginPath: string) => {
        return (
            <Link className="m-0 p-0 nav-link" to={loginPath}>
                <Tooltip placement="top">
                    <div className="mt-2 ms-2">
                        <FontAwesomeIcon icon={faUser} />
                    </div>
                    <span>
                        <small>{loginLabel}</small>
                    </span>
                </Tooltip>
            </Link>
        );
    };

    const authenticatedView = (
        userName: string | null,
        fullName: string | null,
        profilePath: string,
        logoutPath: { pathname: string; state: { local: boolean } },
    ) => {
        return (
            <>
                <Dropdown direction="right" toggle={() => setDropDownOpen(!dropDown)} isOpen={dropDown}>
                    <ButtonWrapper>
                        <DropdownToggle className={className} nav color="dark">
                            <Icon small icon={faUser} className={`ms-2 ms-lg-3`} aria-label="faUser" />
                        </DropdownToggle>
                        {!!dropDown && (
                            <UserCard
                                profilePath={profilePath}
                                fullName={fullName}
                                userName={userName}
                                logoutPath={logoutPath}
                                logoutLabel={logoutLabel}
                                handleOnMenuItemClick={() => {
                                    setDropDownOpen(!dropDown);
                                }}
                            />
                        )}
                    </ButtonWrapper>
                </Dropdown>
            </>
        );
    };

    const { isAuthenticated, userName, fullName } = userInfo;

    if (!isAuthenticated) {
        return anonymousView(ApplicationPaths.Login);
    } else {
        const logoutPath = { pathname: ApplicationPaths.LogOut, state: { local: true } };
        return authenticatedView(userName, fullName, ApplicationPaths.Profile, logoutPath);
    }
};

const ButtonWrapper = styled.div`
    position: relative;
`;

const Icon = styled(HeaderIconButton)<{ isLoading?: boolean }>`
    &.loading {
        animation: pulse 1s infinite;
    }

    @keyframes pulse {
        0% {
            transform: scale(0.95);
        }
        50% {
            transform: scale(1);
        }
        100% {
            transform: scale(0.95);
        }
    }
`;

export default LoginMenu;
