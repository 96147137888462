import { Dispatch, SetStateAction, useState } from 'react';
import Text from '../ui/Text';
import IconButton from '../ui/IconButton';
import { faClose } from '@fortawesome/pro-regular-svg-icons';
import useAppDispatch from 'hooks/useAppDispatch';
import {
    cartUpdatesSet,
    saveOrder,
    selectCartUpdates,
    selectEditEnabled,
    selectOrder,
    selectOrderLoading,
    selectSkusToBeRemoved,
    skusToBeRemovedSet,
} from 'store/reducers/orderSlice';
import { useTranslation } from 'react-i18next';
import useAppSelector from 'hooks/useAppSelector';
import { CartInput, CartItemSkuAddOn } from 'microshop-api';
import styled from 'styled-components';
import Table from '../ui/Table';
import useBreakPoints from 'hooks/useBreakPoints';
import { LoadingWrapper } from 'pages/Orders';
import Spinner from '../ui/Spinner';
import { getTableRows } from './OrderDetailsTable';
import { Delivery } from './Delivery';
import { Actions } from './Actions';

type OrderProps = {
    setOrderDetailsOpen: Dispatch<SetStateAction<boolean>>;
    orderDetailsOpen: true;
    showCloseButton?: boolean;
    removePadding?: boolean;
    editDisabled?: boolean;
};
export const Order = ({
    setOrderDetailsOpen,
    orderDetailsOpen,
    showCloseButton,
    removePadding = false,
    editDisabled = false,
}: OrderProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const bp = useBreakPoints();

    const currentOrder = useAppSelector(selectOrder);
    const orderLoading = useAppSelector(selectOrderLoading);
    const editEnabled = useAppSelector(selectEditEnabled);
    const { addonQuantity, inputQuantity, skuQuantity, comments } = useAppSelector(selectCartUpdates);
    const skusToBeRemoved = useAppSelector(selectSkusToBeRemoved);

    const [showComment, setShowComment] = useState<string>();

    const toggleComment = (sku: string) => {
        setShowComment((prev) => (prev === sku ? undefined : sku));
    };

    const handleCartUpdateSku = (value: string, sku: string) => {
        const updatedCartUpdateSkuQuantityRecord = { ...skuQuantity };
        updatedCartUpdateSkuQuantityRecord[sku] = {
            sku: sku,
            quantity: parseInt(value),
        };

        dispatch(
            cartUpdatesSet({
                addonQuantity: addonQuantity,
                inputQuantity: inputQuantity,
                skuQuantity: updatedCartUpdateSkuQuantityRecord,
                comments: comments,
            }),
        );
    };

    const handleCartUpdateAddon = (
        skuNr: string,
        skuQuantityNr: number,
        addonValue: string,
        addonId: number,
        addons: CartItemSkuAddOn[] | undefined | null,
    ) => {
        const latestSkuQuantity = skuQuantity[skuNr]?.quantity || skuQuantityNr;
        const latestAddonChange: CartItemSkuAddOn[] = addonQuantity?.[skuNr]?.addons;

        const updatedAddons = (latestAddonChange || addons)?.map((addon) => ({
            id: addon.id,
            quantity: addon.id === addonId ? parseInt(addonValue) : addon.quantity,
        }));

        const updatedCartUpdateAddonQuantityRecord = { ...addonQuantity };

        updatedCartUpdateAddonQuantityRecord[skuNr] = {
            sku: skuNr,
            quantity: latestSkuQuantity,
            addons: updatedAddons.map((addon) => {
                return {
                    id: addon.id!,
                    quantity: addon.quantity!,
                };
            }),
        };

        dispatch(
            cartUpdatesSet({
                comments: comments,
                addonQuantity: updatedCartUpdateAddonQuantityRecord,
                inputQuantity: inputQuantity,
                skuQuantity: skuQuantity,
            }),
        );
    };

    const handleCartUpdateInput = (
        collectionId: number,
        quantity: number,
        skuNr: string,
        split: string,
        input: CartInput[],
        splitId?: number | undefined,
    ) => {
        const updatedCartUpdateInputQuantityRecord = { ...inputQuantity };

        updatedCartUpdateInputQuantityRecord[splitId!] = {
            collection: collectionId,
            quantity: quantity,
            sku: skuNr,
            split: split,
            splitId: splitId,
            inputs: input,
        };
        dispatch(
            cartUpdatesSet({
                comments: comments,
                addonQuantity: addonQuantity,
                inputQuantity: updatedCartUpdateInputQuantityRecord,
                skuQuantity: skuQuantity,
            }),
        );
    };

    const handleSkuCommentUpdate = (sku: string, comment: string) => {
        const updatedComments = { ...comments };
        updatedComments[sku] = { skuPattern: sku, value: comment };

        dispatch(
            cartUpdatesSet({
                addonQuantity: addonQuantity,
                inputQuantity: inputQuantity,
                skuQuantity: skuQuantity,
                comments: updatedComments,
            }),
        );
    };

    const handleOnSave = (orderId: number) => {
        dispatch(saveOrder(orderId));
    };

    const handleRemoveRow = (sku: string | null | undefined) => {
        if (!sku) return;
        if (skusToBeRemoved.includes(sku)) {
            dispatch(skusToBeRemovedSet(skusToBeRemoved.filter((item) => item !== sku)));
            return;
        }
        dispatch(skusToBeRemovedSet([...skusToBeRemoved, sku]));
    };

    if (orderLoading)
        return (
            <LoadingWrapper>
                <Spinner center />
            </LoadingWrapper>
        );

    if (!currentOrder) return null;

    const tableHeaders = [t('products'), 'Sku', t('quantity'), t('price'), t('total'), ''];
    const rows = currentOrder?.cart?.items
        ? getTableRows(
              currentOrder?.cart,
              handleCartUpdateSku,
              handleCartUpdateAddon,
              handleCartUpdateInput,
              handleRemoveRow,
              handleSkuCommentUpdate,
              toggleComment,
              showComment,
              editEnabled,
              skusToBeRemoved,
          )
        : [];
    return (
        <OrderDetails removePadding={removePadding}>
            {showCloseButton && (
                <IconButton
                    bgColor="borderLight"
                    icon={faClose}
                    onClick={() => setOrderDetailsOpen(!orderDetailsOpen)}
                ></IconButton>
            )}

            <Table
                bgColor="fillWhite"
                className=" my-4"
                compact={bp.width < 600}
                headers={tableHeaders}
                cols={tableHeaders.length}
                rows={rows}
            />
            <Summasion className="mb-3">
                <h3>Summering</h3>
                <Text>Summa: {currentOrder?.cart?.total}</Text>
            </Summasion>
            {/* {!!currentOrder?.checkout && <Delivery editEnabled={editEnabled} currentOrder={currentOrder} />} */}
            <Delivery editEnabled={editEnabled} currentOrder={currentOrder} />

            {!editDisabled && (
                <Actions editEnabled={editEnabled} handleOnSave={handleOnSave} currentOrder={currentOrder} />
            )}
        </OrderDetails>
    );
};

const OrderDetails = styled.div<{ removePadding?: boolean }>`
    flex: 1;
    width: 100%;
    /* padding: 18px; */
    padding: ${(props) => (props.removePadding ? '0px' : '18px')};
    /* box-shadow: -17px 0px 4px -17px rgba(0, 0, 0, 0.17); */
    box-shadow: ${(props) => (props.removePadding ? 'none' : '-17px 0px 4px -17px rgba(0, 0, 0, 0.17)')};
`;

const Summasion = styled.div`
    background-color: #ffff;
    padding: 20px;
`;
