"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductSizeToJSON = exports.ProductSizeFromJSONTyped = exports.ProductSizeFromJSON = exports.instanceOfProductSize = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the ProductSize interface.
 */
function instanceOfProductSize(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfProductSize = instanceOfProductSize;
function ProductSizeFromJSON(json) {
    return ProductSizeFromJSONTyped(json, false);
}
exports.ProductSizeFromJSON = ProductSizeFromJSON;
function ProductSizeFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'productNumber': !(0, runtime_1.exists)(json, 'productNumber') ? undefined : json['productNumber'],
        'newSize': !(0, runtime_1.exists)(json, 'newSize') ? undefined : json['newSize'],
        'oldSize': !(0, runtime_1.exists)(json, 'oldSize') ? undefined : json['oldSize'],
    };
}
exports.ProductSizeFromJSONTyped = ProductSizeFromJSONTyped;
function ProductSizeToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'productNumber': value.productNumber,
        'newSize': value.newSize,
        'oldSize': value.oldSize,
    };
}
exports.ProductSizeToJSON = ProductSizeToJSON;
