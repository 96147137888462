import { combineReducers } from '@reduxjs/toolkit';
import assortmentReducer from './reducers/assortmentSlice';
import cartReducer from './reducers/cartSlice';
import priceReducer from './reducers/priceSlice';
import productReducer from './reducers/productSlice';
import shopReducer from './reducers/shopSlice';
import userReducer from './reducers/userSlice';
import alertSLice from './reducers/alertSLice';
import attestationSlice from './reducers/attestationSlice';
import orderSlice from './reducers/orderSlice';

export default combineReducers({
    shop: shopReducer,
    cart: cartReducer,
    assortment: assortmentReducer,
    product: productReducer,
    price: priceReducer,
    user: userReducer,
    alert: alertSLice,
    attestation: attestationSlice,
    orders: orderSlice,
});
