import { ImageExtension, ProductImage } from 'microshop-api';
import noPictureSvg from '../assets/images/no_picture.svg';

function getImage(image: ProductImage | undefined, type: ImageType, extenstion?: ImageExtension) {
    if (image && image.customUrl && image?.customUrl?.length > 0) return image.customUrl;

    if (!image || image?.ext === undefined || image?.ext === null) {
        if (extenstion) {
            return MissingImage[extenstion];
        }
        return noPictureSvg;
    }

    // const url = process.env.REACT_APP_MEDIASERVICEURL;
    const url = 'https://images.nwgmedia.com';
    const ext = ImageExtensions[extenstion ?? image.ext];

    return `${url}/${ImageTypes[type]}/${image.imageId}/${image.fileName}${ext}`;
}

export enum ImageType {
    Pdf = 0,
    HighRes = 1,
    Preview = 2,
    LargeThumbNail = 3,
    ThumbNail = 4,
    Original = 5,
}
export const ImageTypes: Record<ImageType, string> = {
    [ImageType.Pdf]: 'pdf',
    [ImageType.HighRes]: 'highres',
    [ImageType.Preview]: 'preview',
    [ImageType.LargeThumbNail]: 'largethumbnail',
    [ImageType.ThumbNail]: 'thumbnail',
    [ImageType.Original]: 'original',
};

export const ImageExtensions: Record<ImageExtension, string> = {
    [ImageExtension.Psd]: '.psd',
    [ImageExtension.Svg]: '.svg',
    [ImageExtension.Png]: '.png',
    [ImageExtension.Jpg]: '.jpg',
    [ImageExtension.Webp]: '.webp',
    [ImageExtension.Jpeg]: '.jpeg',
};

export const MissingImage: Record<ImageExtension, string> = {
    [ImageExtension.Psd]: noPictureSvg,
    [ImageExtension.Svg]: noPictureSvg,
    [ImageExtension.Png]: noPictureSvg,
    [ImageExtension.Jpg]: noPictureSvg,
    [ImageExtension.Webp]: noPictureSvg,
    [ImageExtension.Jpeg]: noPictureSvg,
};

export default getImage;
