import { faLayerPlus, faRemove, faUserPen } from '@fortawesome/pro-regular-svg-icons';
import { faPenAlt } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/ui/Flex';
import Text from 'components/ui/Text';
import IconButton from 'components/ui/IconButton';
import Input from 'components/ui/Input';
import { TableCell, TableRow } from 'components/ui/Table';
import {
    CartInput,
    CartItemProduct,
    CartItemSku,
    CartItemSkuAddOn,
    CartItemVariation,
    CartSummary,
    ImageExtension,
} from 'microshop-api';
import styled from 'styled-components';
import getImage, { ImageType } from 'utils/getImage';
import i18n from 'i18n';
import { useState } from 'react';

export const getTableRows = (
    order: CartSummary,
    handleCartUpdateSku: (value: string, sku: string) => void,
    handleCartUpdateAddon: (
        skuNr: string,
        skuQuantity: number,
        addonValue: string,
        addonId: number,
        addons: CartItemSkuAddOn[] | undefined | null,
    ) => void,
    handleCartUpdateInput: (
        collectionId: number,
        quantity: number,
        skuNr: string,
        split: string,
        input: CartInput[],
        splitId: number | undefined,
    ) => void,
    handleRemoveRow: (sku: string | null | undefined) => void,
    handleSkuCommentUpdate: (sku: string, comment: string) => void,
    toggleComment: (sku: string) => void,
    showComment: string | undefined,
    editEnabled?: boolean,
    skusToBeRemoved?: string[],
): TableRow[] => {
    return order?.items
        ?.flatMap((product) =>
            product.variations?.flatMap((variation) =>
                variation?.skus?.flatMap((sku) => {
                    if (!sku.sku) return undefined;
                    const isFaded = skusToBeRemoved?.includes(sku.sku);

                    const productCells: TableCell[] = [
                        {
                            el: (
                                <>
                                    <ProductSummary
                                        variation={variation}
                                        product={product}
                                        sku={sku}
                                        handleSkuCommentUpdate={handleSkuCommentUpdate}
                                        editEnabled={editEnabled}
                                        toggleComment={toggleComment}
                                    />
                                </>
                            ),
                        },
                        {
                            el: <div>{sku.sku}</div>,
                        },
                        {
                            el: (
                                <div>
                                    {(!editEnabled || !!sku?.inputs?.length) && sku.quantity}
                                    {editEnabled && !sku?.inputs?.length && (
                                        <InputQuantity
                                            name={`sku-${sku.sku}`}
                                            type="number"
                                            defaultValue={sku.quantity}
                                            onChange={(e) => handleCartUpdateSku(e.target.value, sku.sku!)}
                                            min={0}
                                        />
                                    )}
                                </div>
                            ),
                        },
                        {
                            el: <div>{`${sku.price?.customer?.str}`}</div>,
                        },
                        {
                            el: <div>{sku.skuTotal}</div>,
                        },
                        {
                            el: editEnabled ? (
                                <IconButton icon={faRemove} onClick={() => handleRemoveRow(sku.sku)} />
                            ) : (
                                ''
                            ),
                        },
                    ];

                    const commentCells: TableCell[] = [
                        {
                            el: (
                                <Flex column>
                                    <div>{!editEnabled && `${i18n.t('comment')}: ${sku.comment}`}</div>
                                    <div>
                                        {editEnabled && (
                                            <Input
                                                placeholder={i18n.t('comment')}
                                                type="text"
                                                defaultValue={sku?.comment ?? ''}
                                                onBlur={(e) => {
                                                    handleSkuCommentUpdate(sku.sku!, e.target.value ?? '');
                                                }}
                                            />
                                        )}
                                    </div>
                                </Flex>
                            ),
                            span: 6,
                            hideCompactHeader: true,
                        },
                    ];

                    const commentRow: TableRow = { cells: commentCells, removeGap: true, faded: isFaded };

                    const addOnRows: TableRow[] =
                        sku?.addOns?.flatMap((addOn, index) => {
                            const addOnCells: TableCell[] = [
                                {
                                    hideCompactHeader: true,
                                    el: (
                                        <Flex $gap={12}>
                                            <FontAwesomeIcon icon={faLayerPlus} />
                                            <Text>{addOn?.productName}</Text>
                                        </Flex>
                                    ),
                                },
                                {
                                    hideCompactHeader: true,
                                    el: <div></div>,
                                },
                                {
                                    el: (
                                        <div>
                                            {!editEnabled && addOn.quantity}
                                            {editEnabled && (
                                                <InputQuantity
                                                    type="number"
                                                    name="addon"
                                                    min={0}
                                                    defaultValue={addOn.quantity}
                                                    onChange={(e) =>
                                                        handleCartUpdateAddon(
                                                            sku?.sku!,
                                                            sku?.quantity!,
                                                            e.target.value,
                                                            addOn.id!,
                                                            sku?.addOns,
                                                        )
                                                    }
                                                />
                                            )}
                                        </div>
                                    ),
                                },
                                {
                                    hideCompactHeader: true,
                                    el: <div></div>,
                                },
                                {
                                    hideCompactHeader: true,
                                    el: <div></div>,
                                },
                                {
                                    hideCompactHeader: true,
                                    el: <div></div>,
                                },
                            ];

                            return { cells: addOnCells, removeGap: true, faded: isFaded };
                        }) || [];

                    const inputRows: TableRow[] =
                        sku.inputs?.flatMap((input, index) => {
                            const inputCells: TableCell[] = [
                                {
                                    hideCompactHeader: true,
                                    el: (
                                        <Flex column>
                                            <div>
                                                {input.values?.map((value, i) => (
                                                    <Flex column key={i}>
                                                        <Flex $gap={12} align="center">
                                                            <div>{`${index + 1}. `}</div>
                                                            <FontAwesomeIcon icon={faUserPen} />
                                                            <div>
                                                                {value.label}: {value.input}
                                                            </div>
                                                        </Flex>
                                                    </Flex>
                                                ))}
                                            </div>
                                        </Flex>
                                    ),
                                },
                                {
                                    hideCompactHeader: true,
                                    el: <div></div>,
                                },
                                {
                                    el: (
                                        <div>
                                            {!editEnabled && input.quantity}
                                            {editEnabled && (
                                                <InputQuantity
                                                    type="number"
                                                    name="input"
                                                    min={0}
                                                    defaultValue={input.quantity}
                                                    onChange={(e) =>
                                                        handleCartUpdateInput(
                                                            sku.collectionId!,
                                                            parseInt(e.target.value),
                                                            sku.sku!,
                                                            variation.split!,
                                                            input.values?.map((value) => ({
                                                                key: value.key,
                                                                input: value.input,
                                                            })) || [],
                                                            input.id,
                                                        )
                                                    }
                                                />
                                            )}
                                        </div>
                                    ),
                                },
                                {
                                    hideCompactHeader: true,
                                    el: <div></div>,
                                },
                                {
                                    hideCompactHeader: true,
                                    el: <div></div>,
                                },
                                {
                                    hideCompactHeader: true,
                                    el: <div></div>,
                                },
                            ];

                            return { cells: inputCells, removeGap: true, faded: isFaded };
                        }) || [];

                    const rows: TableRow[] = [
                        { cells: productCells, faded: isFaded },
                        ...((!!sku.comment && showComment) || (showComment && editEnabled) ? [commentRow] : []),
                        ...addOnRows,
                        ...inputRows,
                    ];

                    return rows;
                }),
            ),
        )
        .filter((el) => el) as TableRow[];
};

const ProductImage = styled.img`
    width: 48px;
    max-height: 60px;
    object-fit: contain;
`;

const InputQuantity = styled(Input)`
    border-radius: 0;
    width: 88px;
    margin-bottom: 4px;
    ${({ theme }) => theme.media.xs} {
        margin-bottom: 0;
        width: 68px;
    }
`;

const ProductSummary = ({
    variation,
    product,
    sku,
    handleSkuCommentUpdate,
    editEnabled,
    toggleComment,
}: {
    variation: CartItemVariation;
    product: CartItemProduct;
    sku: CartItemSku;
    handleSkuCommentUpdate: (sku: string, comment: string) => void;
    editEnabled: boolean | undefined;
    toggleComment: (sku: string) => void;
}) => {
    const [showComment, setShowComment] = useState(false);

    return (
        <Flex column>
            <Flex $gap={6}>
                <div>
                    <ProductImage src={getImage(variation.image, ImageType.Preview, ImageExtension.Jpg)} />
                </div>
                <Flex column>
                    <Text>{product.name}</Text>
                    <Flex $gap={6}>
                        <Text>{`${variation.color || ''}${variation.color && sku.size ? ' / ' : ''}${
                            sku.size || ''
                        }`}</Text>
                        <FontAwesomeIcon
                            style={{ marginLeft: '10px' }}
                            icon={faPenAlt}
                            onClick={() => {
                                if (!sku?.sku) return;
                                toggleComment(sku.sku);
                            }}
                        />
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
};
