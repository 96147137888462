import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Text from './Text';
import IconButton from './IconButton';
import React, { Fragment } from 'react';
import styled, { ThemeColors } from 'styled-components';

/**
 *  Table component has two different renders: as a regular table with a header row or a compact/mobile version with all cells stacked on top of each other.
 */
type CssOverflow = 'visible' | 'hidden' | 'clip' | 'scroll' | 'auto';

export type TableCell = {
    el: React.ReactNode;
    header?: boolean;
    small?: boolean;
    align?: 'left' | 'right' | 'center';
    hide?: boolean;
    span?: number;
    start?: number;
    overflow?: CssOverflow;
    padding?: string;
    hideCompactHeader?: boolean;
};

export type TableRow = {
    onClick?: () => void;
    header?: boolean;
    cells: TableCell[];
    arrow?: boolean;
    border?: boolean;
    fillWidth?: boolean;
    faded?: boolean;
    headers?: React.ReactNode[];
    useDarkBackground?: boolean;
    key?: string;
    tintOdd?: boolean;
    bgColor?: keyof ThemeColors;
    removeGap?: boolean; // Removes gap to element above. Useful for grouping rows.
    hideCompactPadding?: boolean;
    selected?: boolean;
    bgSelected?: keyof ThemeColors;
};

const CompactTableRow = ({
    onClick,
    cells,
    arrow,
    border = false,
    faded = false,
    bgColor,
    headers,
    tintOdd,
    hideCompactPadding,
    selected,
    bgSelected,
}: TableRow) => {
    return (
        <CompactRow
            faded={faded}
            className={hideCompactPadding ? '' : `p-3`}
            onClick={() => onClick?.()}
            clickable={!!onClick}
            border={border}
            bgColor={bgColor}
            tintOdd={tintOdd}
            selected={selected}
            bgSelected={bgSelected}
        >
            {cells.map((c, index) =>
                c.hide ? null : (
                    <Fragment key={`cell_${index}`}>
                        {!c.hideCompactHeader && headers?.[index] && (
                            <CompactCell className={c.header ? 'f3-700' : ''} overflow={c.overflow}>
                                <Text small bold nowrap>
                                    {headers[index]}
                                </Text>
                            </CompactCell>
                        )}
                        <CompactCell
                            className={c.header ? 'f3-500' : ''}
                            overflow={c.overflow}
                            $span={!!headers?.[index] && !c.hideCompactHeader ? 1 : 2}
                        >
                            {c.small ? (
                                <Text small light nowrap>
                                    {c.el}
                                </Text>
                            ) : (
                                c.el
                            )}
                        </CompactCell>
                    </Fragment>
                ),
            )}
            {arrow && (
                <CompactArrowContainer>
                    <ArrowIcon icon={faArrowRight} />
                </CompactArrowContainer>
            )}
        </CompactRow>
    );
};

type TableProps = {
    rows: TableRow[];
    headers?: React.ReactNode[];
    footers?: TableRow;
    compact?: boolean;
    cols?: number;
    className?: string;
    arrow?: boolean;
    tintOddRows?: boolean;
    renderCompactHeaders?: boolean;
    colLayout?: string; // specify grid col template - no effect on mobile.
    rowLayout?: string; // specify grid row template - no effect on mobile.
    useCellBorder?: boolean;
    showCellReferencing?: boolean;
    bgColor?: keyof ThemeColors;
    selected?: boolean;
    bgSelected?: keyof ThemeColors;
};
const Table: React.FC<TableProps> = ({
    compact,
    headers,
    footers,
    rows,
    cols = 0,
    className,
    arrow,
    tintOddRows = false,
    colLayout,
    rowLayout,
    useCellBorder,
    showCellReferencing,
    bgColor,
    selected,
    bgSelected,
}) => {
    if (compact) {
        return (
            <div className={className ? className : ''}>
                {rows.map((r, i) => (
                    <CompactTableRow
                        key={r.key || `row_${i}`}
                        onClick={r.onClick}
                        cells={r.cells}
                        arrow={arrow}
                        border={r.border}
                        faded={r.faded}
                        headers={headers}
                        tintOdd={tintOddRows}
                        bgColor={bgColor}
                        selected={selected}
                        bgSelected={bgSelected}
                        hideCompactPadding={r.hideCompactPadding}
                    />
                ))}
            </div>
        );
    }
    const gap = !!bgColor;

    return (
        <StyledTable
            gap={gap}
            className={className ? className : ''}
            cols={cols}
            arrow={arrow}
            colLayout={colLayout}
            rowLayout={rowLayout}
        >
            {showCellReferencing &&
                Array.from({ length: cols }).map((_, i) => (
                    <HeaderCell className="f3-500 " key={`cell_ref_${i}`} centered>
                        {i === 0 ? '' : String.fromCharCode(65 + (i - 1))}
                    </HeaderCell>
                ))}
            {headers?.map((h, i) => (
                <Fragment key={`table_header_${i}`}>
                    {i === 0 && showCellReferencing && <CellReferencing>1</CellReferencing>}
                    <HeaderCell className="f3-500" key={i}>
                        {h}
                    </HeaderCell>
                </Fragment>
            ))}
            {arrow && <HeaderCell /> /* Empty header cell (Arrow column) */}
            {rows.map((r, i) => {
                console.log(r.selected);
                return (
                    <Row
                        key={`${r.key ? r.key : i}`}
                        onClick={() => r.onClick?.()}
                        clickable={!!r.onClick}
                        cols={r.fillWidth ? cols : undefined}
                        faded={r.faded}
                        tintOdd={tintOddRows}
                        bgColor={bgColor}
                        selected={r.selected}
                        bgSelected={r.bgSelected}
                        removeGap={gap && r.removeGap}
                    >
                        {showCellReferencing && <CellReferencing>{i + (headers ? 2 : 1)}</CellReferencing>}
                        {r.cells.map((c, j) =>
                            c.hide ? null : (
                                <RowCell
                                    padding={c.padding}
                                    vPadding={bgColor ? 10 : 8}
                                    start={c.start}
                                    span={c.span}
                                    key={`${r.key || i}_${j}`}
                                    justify={c.align}
                                    overflow={c.overflow}
                                    useBorder={useCellBorder}
                                >
                                    {c.el}
                                </RowCell>
                            ),
                        )}
                        {arrow && (
                            <RowCell useBorder={useCellBorder}>
                                <Arrow icon={faArrowRight} bgColor="transparent" />
                            </RowCell>
                        )}
                    </Row>
                );
            })}
            {footers && (
                <Row className="f3-500" cols={footers.fillWidth ? cols : undefined}>
                    {footers.cells?.map((f, i) => (
                        <Cell start={f.start} span={f.span} key={i} useBorder={useCellBorder}>
                            {f.el}
                        </Cell>
                    ))}
                </Row>
            )}
        </StyledTable>
    );
};

export default Table;

type StyledTableProps = {
    cols: number;
    arrow?: boolean;
    tintOddRows?: boolean;
    expandedWidth?: boolean;
    minmax?: string;
    colLayout?: string;
    rowLayout?: string;
    gap?: boolean;
};
const StyledTable = styled.div<StyledTableProps>`
    overflow-x: auto;
    display: grid;
    grid-template-columns: ${({ cols, arrow, colLayout }) =>
        `${colLayout ?? `repeat(${cols}, minmax(80px, auto))`}${arrow ? ' min-content' : ''}`};
    grid-template-rows: minmax(20px, auto) ${({ rowLayout }) => (rowLayout ? rowLayout : 'auto')}; // Header - rows

    ${({ expandedWidth }) => expandedWidth && `width: calc(100% + 20px); margin-left: -10px;`}

    ${({ gap }) => gap && `gap: 3px 0;`}
`;

const Arrow = styled(IconButton)``;

const Cell = styled.div<{
    span?: number;
    start?: number;
    useBorder?: boolean;
    centered?: boolean;
    vPadding?: number;
    padding?: string;
}>`
  font-size: 15px;
  padding: ${({ vPadding }) => `${vPadding ? vPadding : 8}px 6px`};
  ${({ padding }) => padding && `padding: ${padding};`}

  ${({ start }) => start && `grid-column-start: ${start};`}
  ${({ span }) =>
      span &&
      `
    grid-column-end: span ${span};
  `}

  ${({ useBorder }) => useBorder && `border: 0.5px solid #D3D3D3;`}
  ${({ centered }) => centered && `text-align: center;`}
`;

const Row = styled.div<{
    clickable?: boolean;
    cols?: number;
    faded?: boolean;
    tintOdd?: boolean;
    bgColor?: keyof ThemeColors;
    removeGap?: boolean;
    selected?: boolean;
    bgSelected?: keyof ThemeColors;
}>`
  display: contents;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'normal')};

  ${Cell} {
    ${({ removeGap }) =>
        removeGap &&
        `
      margin-top: -3px;
      height: calc(100% + 3px);
    `}
    ${({ faded }) => (faded ? 'opacity: 0.5;' : '')}
    transition: all 0.1s linear;
    ${(p) => p.cols && `grid-column: span ${p.cols};`}
    ${({ bgColor, theme, selected }) => bgColor && !selected && `background-color: ${theme.colors[bgColor]};`}
    ${({ theme, selected, bgSelected }) => selected && bgSelected && `background-color: ${theme.colors[bgSelected]};`}
  }

  ${({ tintOdd, selected }) =>
      tintOdd &&
      !selected &&
      `
      &:nth-child(2n) {
        ${Cell} {
          background-color: rgba(00,00,00,0.025);
        }
      }
    `}

  &:hover ${Cell} {
    ${({ clickable, tintOdd, selected }) =>
        clickable && !selected && `background-color: ${tintOdd ? 'rgba(00,00,00,0.05)' : 'rgba(00,00,00,0.025)'};`};
  }
`;

const HeaderCell = styled(Cell)`
    font-size: 14px;
    align-self: baseline;
    padding: 0px 6px;
`;

const RowCell = styled(Cell)<{ justify?: 'left' | 'right' | 'center'; overflow?: CssOverflow }>`
    text-overflow: ellipsis;
    display: flex;
    ${({ overflow }) => (overflow ? `overflow: ${overflow};` : 'overflow: hidden;')}
    white-space: pre-line;
    height: 100%;
    align-items: center;
    ${({ justify }) => (justify ? `justify-content: ${justify};` : undefined)}
`;

const CompactRow = styled.div<{
    selected?: boolean;
    clickable?: boolean;
    border: boolean;
    faded?: boolean;
    tintOdd?: boolean;
    bgColor?: keyof ThemeColors;
    bgSelected?: keyof ThemeColors;
}>`
    ${({ faded }) => (faded ? 'opacity: 0.5;' : '')}
    transition: background-color 0.1s linear;
    cursor: ${({ clickable }) => (clickable ? 'pointer' : 'normal')};
    border-top: ${({ theme, border }) => (border ? `1px solid ${theme.colors.borderLight};` : '')};
    display: grid;
    grid-template-columns: min-content 1fr;
    gap: 6px 1rem;
    position: relative;

    ${({ bgColor, theme }) =>
        bgColor &&
        `
    background-color: ${theme.colors[bgColor]};
    margin-bottom: 3px;
  `}

    ${({ theme, selected, bgSelected }) => selected && bgSelected && `background-color: ${theme.colors[bgSelected]};`}

    ${({ tintOdd, selected }) =>
        tintOdd &&
        !selected &&
        `
      &:nth-child(2n) {
        background-color: rgba(00,00,00,0.025);
      }
    `}

  &:hover {
        ${({ clickable, tintOdd, selected }) =>
            clickable && !selected && `background-color: ${tintOdd ? 'rgba(00,00,00,0.05)' : 'rgba(00,00,00,0.025)'};`};
    }

    ${({ selected }) => (selected ? 'background-color:rgba(00,00,00,0.06);' : '')}
`;

const CompactCell = styled.div<{ overflow?: CssOverflow; $span?: number }>`
    font-size: 14px;
    text-overflow: ellipsis;
    ${({ overflow }) => (overflow ? `overflow: ${overflow};` : 'overflow: hidden;')}
    white-space: nowrap;
    ${({ $span }) => $span && `grid-column: span ${$span};`}
`;

const CompactArrowContainer = styled.div`
    position: absolute;
    right: 12px;
    top: 8px;
`;

const ArrowIcon = styled(FontAwesomeIcon)`
    color: ${({ theme }) => theme.colors.textLight};
`;

const CellReferencing = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    font-size: 14px;
`;
