import Input, { Label } from './ui/Input';
import styled from 'styled-components';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import Flex from './ui/Flex';
import Datepicker from './ui/Datepicker';
import { useTranslation } from 'react-i18next';
import useAppSelector from 'hooks/useAppSelector';
import { searchSet, selectSearch } from 'store/reducers/orderSlice';
import useAppDispatch from 'hooks/useAppDispatch';

export const OrderFilter = () => {
    const dispatch = useAppDispatch();
    const search = useAppSelector(selectSearch);

    const { t } = useTranslation();

    return (
        <Flex column className="w-100 mb-4">
            <SearchWrapper compact={false}>
                <SearchInputWrapper>
                    <Label>{t('search')}</Label>
                    <SearchInput
                        defaultValue={search.searchPhrase}
                        type="text"
                        name="Search"
                        icon={faSearch}
                        rightSidedIcon
                        onChange={(e) => {
                            dispatch(searchSet({ ...search, searchPhrase: e.target.value, page: 1 }));
                        }}
                        placeholder="T.ex. ordernummer, email eller telefonnummer"
                        paddingRight={'59px'}
                    />
                </SearchInputWrapper>
                <DatePickerWrapper className="">
                    <Label>{t('startDate')}</Label>
                    <Datepicker
                        selectedDate={search.from}
                        header={t('startDate')}
                        onChange={(date) =>
                            date instanceof Date && dispatch(searchSet({ ...search, from: date, page: 1 }))
                        }
                        showIcon
                        filter={'None'}
                    />
                </DatePickerWrapper>
                <DatePickerWrapper>
                    <Label>{t('endDate')}</Label>
                    <Datepicker
                        selectedDate={search.to}
                        header={t('endDate')}
                        onChange={(date) =>
                            date instanceof Date && dispatch(searchSet({ ...search, to: date, page: 1 }))
                        }
                        showIcon
                        filter={'None'}
                    />
                </DatePickerWrapper>
            </SearchWrapper>
        </Flex>
    );
};

const SearchInput = styled(Input)``;

const SearchWrapper = styled.div<{ compact: boolean }>`
    display: flex;
    align-items: end;
    gap: 6px;
    font-size: 14px;
    width: 100%;
    flex-grow: 1;

    ${({ theme }) => theme.media.mdBelow} {
        display: block;
    }
`;

const SearchInputWrapper = styled.div`
    ${({ theme }) => theme.media.mdBelow} {
        margin-bottom: 1rem;
    }
`;

const DatePickerWrapper = styled.div`
    ${({ theme }) => theme.media.mdBelow} {
        margin-bottom: 1rem;
    }
`;
