"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShopAttestationOverviewPageInfoToJSON = exports.ShopAttestationOverviewPageInfoFromJSONTyped = exports.ShopAttestationOverviewPageInfoFromJSON = exports.instanceOfShopAttestationOverviewPageInfo = void 0;
var runtime_1 = require("../runtime");
var ShopAttestationOverview_1 = require("./ShopAttestationOverview");
/**
 * Check if a given object implements the ShopAttestationOverviewPageInfo interface.
 */
function instanceOfShopAttestationOverviewPageInfo(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfShopAttestationOverviewPageInfo = instanceOfShopAttestationOverviewPageInfo;
function ShopAttestationOverviewPageInfoFromJSON(json) {
    return ShopAttestationOverviewPageInfoFromJSONTyped(json, false);
}
exports.ShopAttestationOverviewPageInfoFromJSON = ShopAttestationOverviewPageInfoFromJSON;
function ShopAttestationOverviewPageInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'pageSize': !(0, runtime_1.exists)(json, 'pageSize') ? undefined : json['pageSize'],
        'currentPage': !(0, runtime_1.exists)(json, 'currentPage') ? undefined : json['currentPage'],
        'totalItems': !(0, runtime_1.exists)(json, 'totalItems') ? undefined : json['totalItems'],
        'totalPages': !(0, runtime_1.exists)(json, 'totalPages') ? undefined : json['totalPages'],
        'items': !(0, runtime_1.exists)(json, 'items') ? undefined : (json['items'] === null ? null : json['items'].map(ShopAttestationOverview_1.ShopAttestationOverviewFromJSON)),
        'hasNextPage': !(0, runtime_1.exists)(json, 'hasNextPage') ? undefined : json['hasNextPage'],
        'hasPreviousPage': !(0, runtime_1.exists)(json, 'hasPreviousPage') ? undefined : json['hasPreviousPage'],
    };
}
exports.ShopAttestationOverviewPageInfoFromJSONTyped = ShopAttestationOverviewPageInfoFromJSONTyped;
function ShopAttestationOverviewPageInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'pageSize': value.pageSize,
        'currentPage': value.currentPage,
        'totalItems': value.totalItems,
        'totalPages': value.totalPages,
        'items': value.items === undefined ? undefined : (value.items === null ? null : value.items.map(ShopAttestationOverview_1.ShopAttestationOverviewToJSON)),
    };
}
exports.ShopAttestationOverviewPageInfoToJSON = ShopAttestationOverviewPageInfoToJSON;
