"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AttestAttestationToJSON = exports.AttestAttestationFromJSONTyped = exports.AttestAttestationFromJSON = exports.instanceOfAttestAttestation = void 0;
var runtime_1 = require("../runtime");
var ShopAttestationStatus_1 = require("./ShopAttestationStatus");
/**
 * Check if a given object implements the AttestAttestation interface.
 */
function instanceOfAttestAttestation(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfAttestAttestation = instanceOfAttestAttestation;
function AttestAttestationFromJSON(json) {
    return AttestAttestationFromJSONTyped(json, false);
}
exports.AttestAttestationFromJSON = AttestAttestationFromJSON;
function AttestAttestationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'attestationId': !(0, runtime_1.exists)(json, 'attestationId') ? undefined : json['attestationId'],
        'status': !(0, runtime_1.exists)(json, 'status') ? undefined : (0, ShopAttestationStatus_1.ShopAttestationStatusFromJSON)(json['status']),
        'comment': !(0, runtime_1.exists)(json, 'comment') ? undefined : json['comment'],
    };
}
exports.AttestAttestationFromJSONTyped = AttestAttestationFromJSONTyped;
function AttestAttestationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'attestationId': value.attestationId,
        'status': (0, ShopAttestationStatus_1.ShopAttestationStatusToJSON)(value.status),
        'comment': value.comment,
    };
}
exports.AttestAttestationToJSON = AttestAttestationToJSON;
