// import { faAngleDown, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { faAngleDown, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { shade, transparentize } from 'polished';
import { useTranslation } from 'react-i18next';
import Select, {
    ClearIndicatorProps,
    components,
    DropdownIndicatorProps,
    GroupBase,
    MultiValueRemoveProps,
    OptionProps,
    Props,
    StylesConfig,
} from 'react-select';
import { MenuListProps } from 'react-select/dist/declarations/src/components/Menu';
import styled, { ThemeColors, useTheme } from 'styled-components';
import { SelectOption as OptionBase } from 'types/Option';
import { ThemeColorsAll } from 'types/ThemeColorsAll';
import Flex from './Flex';
import Link from './Link';
import LinkStyle from './LinkStyle';
// import Text from './Text/Text';
import Text from './Text';

const NwgOption = <
    Option extends OptionBase,
    IsMulti extends boolean = true,
    Group extends GroupBase<Option> = GroupBase<Option>
>(
    props: OptionProps<Option, IsMulti, Group>,
) => {
    const icon = props.data.icon;
    const iconDefinition = props.data.iconDefinition;
    const iconSrc = props.data.iconSrc;
    const value = props.data.value;
    const isLink = props.data.link;

    const body = (
        <Flex align="center">
            {icon && (
                <div className="mr-2">
                    {icon && <IconWrapper>{icon}</IconWrapper>}
                    {iconSrc && <Image src={iconSrc} />}
                    {iconDefinition && <FontAwesomeIcon icon={iconDefinition} />}
                </div>
            )}
            {props.children}
        </Flex>
    );
    return (
        <components.Option {...props} isMulti>
            {isLink && (typeof value === 'string' || value instanceof Location) ? (
                <Link to={value} noStyle>
                    {body}
                </Link>
            ) : (
                body
            )}
        </components.Option>
    );
};

const SelectAllMenuList = <
    Option extends OptionBase,
    IsMulti extends boolean = true,
    Group extends GroupBase<Option> = GroupBase<Option>
>(
    props: MenuListProps<Option, IsMulti, Group>,
) => {
    const selectedValues = props.getValue();
    const allValues = props.options.flatMap((o) => ('options' in o ? o.options : o));
    const diff = allValues.filter((v) => !selectedValues.some((sv) => sv.value === v.value));

    const selectAll = selectedValues && diff.length;

    return (
        <>
            <components.MenuList {...props} isMulti>
                {props.children}
            </components.MenuList>
            <Flex justify="end" className="p-1">
                <LinkStyle
                    onClick={() => {
                        if (selectAll) {
                            props.setValue([...selectedValues, ...diff] as any, 'select-option'); // Can't find the solution to get the correct type here
                        } else {
                            props.setValue([] as any, 'deselect-option');
                        }
                    }}
                    className="pointer mr-2"
                >
                    {selectAll ? 'Select All' : 'Select None'}
                </LinkStyle>
            </Flex>
        </>
    );
};

const NwgDropdownIndicator = <Option extends OptionBase, IsMulti extends boolean, Group extends GroupBase<Option>>(
    props: DropdownIndicatorProps<Option, IsMulti, Group>,
) => {
    return (
        <components.DropdownIndicator {...props}>
            <IndicatorIcon size="1x" icon={faAngleDown} />
        </components.DropdownIndicator>
    );
};

const NwgClearIndicator = <
    Option extends OptionBase,
    IsMulti extends boolean = true,
    Group extends GroupBase<Option> = GroupBase<Option>
>(
    props: ClearIndicatorProps<Option, IsMulti, Group>,
) => (
    <components.ClearIndicator {...props}>
        <IndicatorIcon fontSize={18} icon={faTimes} />
    </components.ClearIndicator>
);

const NwgMultiValueRemove = <
    Option extends OptionBase,
    IsMulti extends boolean = true,
    Group extends GroupBase<Option> = GroupBase<Option>
>(
    props: MultiValueRemoveProps<Option, IsMulti, Group>,
) => {
    return (
        <components.MultiValueRemove {...props}>
            <IndicatorIcon fontSize={12} icon={faTimes} />
        </components.MultiValueRemove>
    );
};

type SelectProps = {
    /** The label visible above the select field. */
    label?: string | undefined | null;
    /** Adds a button to select all options in one click. */
    selectAll?: boolean;
    /** Turns the label into a lighter shade of gray. */
    lightLabel?: boolean;
    /** Decreases the font size of the label. */
    smallLabel?: boolean;
    /** Makes the select toggle smaller and borderless */
    tiny?: boolean;
    /** Toggle button bg color */
    toggleBgColor?: keyof ThemeColors;
    /**
     * Added through react-select.d.ts - so we can get types inside custom components that need the props.
     *
     * indicatorIcon?: IconDefinition; -> Custom icon
     *
     * */
};

const ReactSelect = <
    Option extends OptionBase,
    IsMulti extends boolean,
    Group extends GroupBase<Option> & { color?: ThemeColorsAll } = GroupBase<Option>
>({
    isMulti,
    label = null,
    selectAll = false,
    lightLabel = false,
    smallLabel = false,
    isSearchable = true,
    tiny = false,
    toggleBgColor,
    ...props
}: Props<Option, IsMulti, Group> & SelectProps) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const selectStyles: StylesConfig<Option, IsMulti, Group> = {
        input: (base) => ({
            ...base,
        }),
        dropdownIndicator: (base) => ({
            ...base,
            color: isSearchable ? base.color : theme.colors.text,
            padding: tiny ? '0 10px 0 0' : base.padding,
            ':hover': isSearchable
                ? base[':hover']
                : {
                      color: theme.colors.text,
                  },
        }),
        indicatorSeparator: (base) => ({
            ...base,
            display: tiny ? 'none' : base.display,
        }),
        control: (base) => ({
            ...base,
            border: isSearchable ? `1px solid ${theme.colors.borderLight}` : 'none',
            cursor: isSearchable ? base.cursor : 'pointer',
            boxShadow: 'none',
            minHeight: tiny ? 'auto' : '40px',
            backgroundColor: toggleBgColor ? theme.colors[toggleBgColor] : base.backgroundColor,
            transition: 'all 0.15s linear',
            textAlign: isSearchable ? 'initial' : 'center',

            ':hover': {
                borderColor: theme.colors.border,
                backgroundColor: toggleBgColor
                    ? shade(0.03, theme.colors[toggleBgColor])
                    : isSearchable
                    ? base.backgroundColor
                    : shade(0.03, 'white'),
            },
        }),
        menuList: (base) => ({
            ...base,
            padding: 0,
        }),
        menu: (base) => ({
            ...base,
            width: 'max-content',
            minWidth: '100%',
        }),
        groupHeading: (base, state) => ({
            ...base,
            color: state.data.color
                ? { ...theme.colors, ...theme.webColors }[state.data.color]
                : theme.colors.textLight,
            borderLeft: state.data.color
                ? `3px solid ${{ ...theme.colors, ...theme.webColors }[state.data.color]}`
                : base.borderLeft,
            margin: 0,
            height: '2em',
        }),
        multiValue: (base, state) => ({
            ...base,
            backgroundColor: state.data.color
                ? transparentize(0.65, { ...theme.colors, ...theme.webColors }[state.data.color])
                : base.backgroundColor,
        }),
        option: (base, state) => ({
            ...base,
            borderLeft: state.data.color
                ? `3px solid ${{ ...theme.colors, ...theme.webColors }[state.data.color]}`
                : base.borderLeft,
            cursor: 'pointer',
        }),
    };

    const modifiedComponents = {
        DropdownIndicator: NwgDropdownIndicator,
        ClearIndicator: NwgClearIndicator,
        MultiValueRemove: NwgMultiValueRemove,
        MenuList: selectAll && isMulti ? SelectAllMenuList : components.MenuList,
        Option: NwgOption,
    };

    return (
        <>
            {label && (
                <Label
                    htmlFor={props.id}
                    className={!lightLabel ? 'f3-700' : undefined}
                    lightLabel={lightLabel}
                    smallLabel={smallLabel}
                >
                    {label}
                    {props.required && (
                        <Text small className="d-inline" color="textFaded">
                            {' *'}
                        </Text>
                    )}
                </Label>
            )}
            <Select
                {...props}
                isMulti={isMulti}
                isSearchable={isSearchable}
                // label={label}
                styles={selectStyles}
                theme={(base) => ({
                    ...base,
                    borderRadius: 2,
                    colors: {
                        ...base.colors,
                        primary: transparentize(0.2, theme.colors.accent), // Menulist option selected
                        // primary75: transparentize(0.4, theme.colors.accent),
                        primary50: transparentize(0.6, theme.colors.accent), // Menulist option active
                        primary25: transparentize(0.8, theme.colors.accent), // Menulist option hovered
                        // neutral50: theme.colors.error, // placeholder choose
                        // neutral80: theme.colors.error, // placeholder selected + multiselect selected label
                    },
                })}
                noOptionsMessage={(_input) => t('search.empty')}
                components={modifiedComponents}
                placeholder={props.placeholder ?? t('action.select')}
            />
        </>
    );
};

export default ReactSelect;

export const Label = styled.label<{
    lightLabel?: boolean;
    smallLabel?: boolean;
}>`
    width: 100%;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: ${({ smallLabel }) => (smallLabel ? 12 : 15)}px;
    letter-spacing: 0.5px;
    color: ${({ lightLabel, theme }) => (lightLabel ? theme.colors.textLight : theme.colors.text)};
    transition: all 0.3s ease-in-out;
    margin-bottom: 0;
`;

const IndicatorIcon = styled(FontAwesomeIcon)`
    width: 20px;
    cursor: pointer;
`;

const IconWrapper = styled.div`
    width: 20px;
    max-height: 1em;
`;
const Image = styled.img`
    height: 1em;
`;
