"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductSizeChangeAttestationToJSON = exports.ProductSizeChangeAttestationFromJSONTyped = exports.ProductSizeChangeAttestationFromJSON = exports.instanceOfProductSizeChangeAttestation = void 0;
var runtime_1 = require("../runtime");
var ProductSize_1 = require("./ProductSize");
/**
 * Check if a given object implements the ProductSizeChangeAttestation interface.
 */
function instanceOfProductSizeChangeAttestation(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfProductSizeChangeAttestation = instanceOfProductSizeChangeAttestation;
function ProductSizeChangeAttestationFromJSON(json) {
    return ProductSizeChangeAttestationFromJSONTyped(json, false);
}
exports.ProductSizeChangeAttestationFromJSON = ProductSizeChangeAttestationFromJSON;
function ProductSizeChangeAttestationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'productSizes': !(0, runtime_1.exists)(json, 'productSizes') ? undefined : (json['productSizes'] === null ? null : json['productSizes'].map(ProductSize_1.ProductSizeFromJSON)),
    };
}
exports.ProductSizeChangeAttestationFromJSONTyped = ProductSizeChangeAttestationFromJSONTyped;
function ProductSizeChangeAttestationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'productSizes': value.productSizes === undefined ? undefined : (value.productSizes === null ? null : value.productSizes.map(ProductSize_1.ProductSizeToJSON)),
    };
}
exports.ProductSizeChangeAttestationToJSON = ProductSizeChangeAttestationToJSON;
