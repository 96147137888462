"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShopCustomerOrderOverviewToJSON = exports.ShopCustomerOrderOverviewFromJSONTyped = exports.ShopCustomerOrderOverviewFromJSON = exports.instanceOfShopCustomerOrderOverview = void 0;
var runtime_1 = require("../runtime");
var CommDate_1 = require("./CommDate");
/**
 * Check if a given object implements the ShopCustomerOrderOverview interface.
 */
function instanceOfShopCustomerOrderOverview(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfShopCustomerOrderOverview = instanceOfShopCustomerOrderOverview;
function ShopCustomerOrderOverviewFromJSON(json) {
    return ShopCustomerOrderOverviewFromJSONTyped(json, false);
}
exports.ShopCustomerOrderOverviewFromJSON = ShopCustomerOrderOverviewFromJSON;
function ShopCustomerOrderOverviewFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'orderNumber': !(0, runtime_1.exists)(json, 'orderNumber') ? undefined : json['orderNumber'],
        'created': !(0, runtime_1.exists)(json, 'created') ? undefined : (0, CommDate_1.CommDateFromJSON)(json['created']),
        'currency': !(0, runtime_1.exists)(json, 'currency') ? undefined : json['currency'],
        'deliveryAdressEmail': !(0, runtime_1.exists)(json, 'deliveryAdressEmail') ? undefined : json['deliveryAdressEmail'],
        'deliveryAdressPhone': !(0, runtime_1.exists)(json, 'deliveryAdressPhone') ? undefined : json['deliveryAdressPhone'],
        'deliveryAdressName': !(0, runtime_1.exists)(json, 'deliveryAdressName') ? undefined : json['deliveryAdressName'],
        'totalIncVat': !(0, runtime_1.exists)(json, 'totalIncVat') ? undefined : json['totalIncVat'],
        'total': !(0, runtime_1.exists)(json, 'total') ? undefined : json['total'],
        'totalExVat': !(0, runtime_1.exists)(json, 'totalExVat') ? undefined : json['totalExVat'],
        'quantity': !(0, runtime_1.exists)(json, 'quantity') ? undefined : json['quantity'],
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
    };
}
exports.ShopCustomerOrderOverviewFromJSONTyped = ShopCustomerOrderOverviewFromJSONTyped;
function ShopCustomerOrderOverviewToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'orderNumber': value.orderNumber,
        'created': (0, CommDate_1.CommDateToJSON)(value.created),
        'currency': value.currency,
        'deliveryAdressEmail': value.deliveryAdressEmail,
        'deliveryAdressPhone': value.deliveryAdressPhone,
        'deliveryAdressName': value.deliveryAdressName,
        'totalIncVat': value.totalIncVat,
        'total': value.total,
        'totalExVat': value.totalExVat,
        'quantity': value.quantity,
        'id': value.id,
    };
}
exports.ShopCustomerOrderOverviewToJSON = ShopCustomerOrderOverviewToJSON;
