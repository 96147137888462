import Button from 'components/ui/Button';
import Flex from 'components/ui/Flex';
import useAppDispatch from 'hooks/useAppDispatch';
import { OrderCartCheckoutWithAttestation } from 'microshop-api';
import { editEnabledSet, orderSet, skusToBeRemovedSet } from 'store/reducers/orderSlice';

type ActionsProps = {
    editEnabled: boolean;
    handleOnSave: (orderId: number) => void;
    currentOrder: OrderCartCheckoutWithAttestation;
};
export const Actions = ({ editEnabled, handleOnSave, currentOrder }: ActionsProps) => {
    const dispatch = useAppDispatch();
    return (
        <>
            <Flex className="mt-2 mb-3" justify="end" $gap={6}>
                {!editEnabled && (
                    <Button bgColor="fillGray" onClick={() => dispatch(editEnabledSet(true))}>
                        Redigera
                    </Button>
                )}
                {editEnabled && (
                    <>
                        {' '}
                        <Button
                            bgColor="fillGray"
                            onClick={() => {
                                dispatch(skusToBeRemovedSet([]));
                                dispatch(orderSet(currentOrder));
                            }}
                        >
                            Ångra
                        </Button>{' '}
                        <Button
                            bgColor="fillGray"
                            onClick={() => {
                                handleOnSave(currentOrder?.orderId!);
                            }}
                        >
                            Spara
                        </Button>
                    </>
                )}
            </Flex>
        </>
    );
};
