"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChangeAttestationToJSON = exports.ChangeAttestationFromJSONTyped = exports.ChangeAttestationFromJSON = exports.instanceOfChangeAttestation = void 0;
var runtime_1 = require("../runtime");
var CommDate_1 = require("./CommDate");
var ShopAttestationStatus_1 = require("./ShopAttestationStatus");
var ShopAttestationType_1 = require("./ShopAttestationType");
/**
 * Check if a given object implements the ChangeAttestation interface.
 */
function instanceOfChangeAttestation(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfChangeAttestation = instanceOfChangeAttestation;
function ChangeAttestationFromJSON(json) {
    return ChangeAttestationFromJSONTyped(json, false);
}
exports.ChangeAttestationFromJSON = ChangeAttestationFromJSON;
function ChangeAttestationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'reviewedBy': !(0, runtime_1.exists)(json, 'reviewedBy') ? undefined : json['reviewedBy'],
        'requestedUser': !(0, runtime_1.exists)(json, 'requestedUser') ? undefined : json['requestedUser'],
        'dateCreated': !(0, runtime_1.exists)(json, 'dateCreated') ? undefined : (0, CommDate_1.CommDateFromJSON)(json['dateCreated']),
        'dateChanged': !(0, runtime_1.exists)(json, 'dateChanged') ? undefined : (0, CommDate_1.CommDateFromJSON)(json['dateChanged']),
        'status': !(0, runtime_1.exists)(json, 'status') ? undefined : (0, ShopAttestationStatus_1.ShopAttestationStatusFromJSON)(json['status']),
        'type': !(0, runtime_1.exists)(json, 'type') ? undefined : (0, ShopAttestationType_1.ShopAttestationTypeFromJSON)(json['type']),
        'data': !(0, runtime_1.exists)(json, 'data') ? undefined : json['data'],
        'comment': !(0, runtime_1.exists)(json, 'comment') ? undefined : json['comment'],
    };
}
exports.ChangeAttestationFromJSONTyped = ChangeAttestationFromJSONTyped;
function ChangeAttestationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'reviewedBy': value.reviewedBy,
        'requestedUser': value.requestedUser,
        'dateCreated': (0, CommDate_1.CommDateToJSON)(value.dateCreated),
        'dateChanged': (0, CommDate_1.CommDateToJSON)(value.dateChanged),
        'status': (0, ShopAttestationStatus_1.ShopAttestationStatusToJSON)(value.status),
        'type': (0, ShopAttestationType_1.ShopAttestationTypeToJSON)(value.type),
        'data': value.data,
        'comment': value.comment,
    };
}
exports.ChangeAttestationToJSON = ChangeAttestationToJSON;
