import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type Alert = {
    title?: string;
    description?: string;
    content?: React.ReactNode;
    contentWrapped?: React.ReactNode;
    onOk?: () => void;
    onCancel?: () => void;
    stretchButtons?: boolean;
    color?: 'accent' | 'attention' | 'error' | 'success' | 'warning' | 'main' | 'fillBlack';
    icon?: IconDefinition;
    okLabel?: string;
    cancelLabel?: string;
    hideCancel?: boolean;
    hideOk?: boolean;
    hideIcon?: boolean;
    showCustomerServiceInfo?: boolean;
    maxWidth?: number;
    customButtons?: AlertCustomButton[];
};

export type AlertCustomButton = {
    onClick: () => void;
    label: string;
};

interface AlertState {
    alerts: Alert[];
    currentAlert: Alert | null | undefined;
}

export const initialState: AlertState = {
    alerts: [],
    currentAlert: null,
};

const alertSlice = createSlice({
    name: 'alert',
    initialState,
    reducers: {
        alertQueued(state, action: PayloadAction<Alert>) {
            state.alerts.push(action.payload);
        },
        nextAlerted(state) {
            state.currentAlert = state.alerts.pop();
        },
        alertHandled(state) {
            state.currentAlert = null;
        },
        clearAlerts(state) {
            state.alerts = [];
        },
    },
    extraReducers: () => {},
});

// API
export const { alertQueued, nextAlerted, alertHandled, clearAlerts } = alertSlice.actions;
export default alertSlice.reducer;
