import { UserApi } from 'microshop-api';
import { config } from './api';

const userApi = new UserApi(config);

const user = {
    resetPassword: (email: string): Promise<boolean> =>
        userApi.userResetPasswordPOST({ resetPasswordModel: { email } }),
};

export { user };
