import { faCheck, faExclamationCircle, faInfoCircle, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from './Flex';
import Text from './Text';
import styled, { DefaultTheme, ThemeColors, useTheme } from 'styled-components';

export type IconToastPreset = 'success' | 'error';

type Props = {
    text: string;
    preset?: IconToastPreset;
    icon?: IconDefinition;
    iconColor?: keyof ThemeColors;
};

const IconToast = ({ text, iconColor, icon, preset }: Props) => {
    const theme = useTheme();
    const color = iconColor ? theme.colors[iconColor] : getColor(theme, preset);

    return (
        <Toast justify="between" align="center">
            <QuantityWrapper className="align-self-stretch" align="center" justify="center">
                <FontAwesomeIcon color={color} size="2xl" icon={icon ?? getIcon(preset)}></FontAwesomeIcon>
            </QuantityWrapper>
            <TextWrapper align="center">
                <Text>{text}</Text>
            </TextWrapper>
        </Toast>
    );
};

function getColor(theme: DefaultTheme, preset?: IconToastPreset) {
    switch (preset) {
        case 'error':
            return theme.colors.error;
        case 'success':
            return theme.colors.success;
        default:
            return theme.colors.text;
    }
}

function getIcon(preset?: IconToastPreset) {
    switch (preset) {
        case 'error':
            return faExclamationCircle;
        case 'success':
            return faCheck;
        default:
            return faInfoCircle;
    }
}

export default IconToast;

const Toast = styled(Flex)`
    min-height: 50px;
`;

const TextWrapper = styled(Flex)`
    background: white;
    padding: 1rem;
    max-width: 300px;
`;

const QuantityWrapper = styled(Flex)`
    background-color: ${({ theme }) => theme.colors.fill};
    padding: 1rem;
`;
