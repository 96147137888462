// import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import Button from './Button';
// import CustomerServiceInfo from 'components/User/CustomerServiceInfo';
import useAppDispatch from '../../hooks/useAppDispatch';
import useAppSelector from '../../hooks/useAppSelector';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { ThemeColors } from 'styled-components';
import Flex from '../ui/Flex';
import { Alert, alertHandled, nextAlerted } from '../../store/reducers/alertSLice';

const Alerts = () => {
    const { alerts, currentAlert } = useAppSelector((state) => state.alert);
    const dispatch = useAppDispatch();
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!currentAlert && alerts.length) {
            dispatch(nextAlerted());
        }
    }, [alerts, currentAlert, dispatch]);

    useEffect(() => {
        const currentRef = ref.current;
        if (currentAlert && currentRef) {
            // disableBodyScroll(currentRef);
        }
        return () => {
            // if (currentRef) enableBodyScroll(currentRef);
        };
    }, [currentAlert, ref]);

    if (!currentAlert) return null;

    return (
        <FullSize ref={ref} className="exclude-click-listener">
            <Overlay></Overlay>
            <CurrentAlert {...currentAlert} />
        </FullSize>
    );
};

const CurrentAlert = ({
    content,
    contentWrapped,
    title,
    description,
    okLabel,
    cancelLabel,
    onOk,
    onCancel,
    stretchButtons,
    icon = faExclamationCircle,
    color = 'error',
    hideCancel = false,
    hideOk = false,
    hideIcon = false,
    showCustomerServiceInfo = false,
    maxWidth,
    customButtons,
}: Alert) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const _onOk = () => {
        dispatch(alertHandled());
        if (onOk) onOk();
    };

    const _onCancel = () => {
        dispatch(alertHandled());
        if (onCancel) onCancel();
    };

    const _onCustomButtonClick = (onClick: () => void) => () => {
        dispatch(alertHandled());
        onClick();
    };

    return (
        <Wrapper className="f1-400" align="center" justify="center" data-exclude-click>
            <AlertCard maxWidth={maxWidth}>
                <ContentWrapper>
                    <Flex align="center" $gap={14}>
                        {!hideIcon && <Icon className="mr-4" col={color} icon={icon} />}
                        <div>
                            <Title className="f3-500">{title ?? t('error.error', 'Error!')}</Title>
                            {description && <Description>{description}</Description>}
                            {contentWrapped != null && contentWrapped}
                        </div>
                    </Flex>
                </ContentWrapper>
                {content != null && content}
                {showCustomerServiceInfo && (
                    <CustomerServiceWrapper className="py-3 mt-3">
                        {/* <CustomerServiceInfo /> */}
                    </CustomerServiceWrapper>
                )}
                {!hideOk || !hideCancel ? (
                    <Buttons stretchButtons={stretchButtons} className="mt-3">
                        {!hideOk && (
                            <OkButton
                                data-exclude-click
                                height={44}
                                bgColor={color}
                                color={'fillWhite'}
                                onClick={_onOk}
                                fullwidth={hideCancel && stretchButtons !== false}
                            >
                                {okLabel ?? t('action.ok', 'Ok')}
                            </OkButton>
                        )}
                        {customButtons?.map((b, i) => (
                            <OkButton
                                data-exclude-click
                                key={i}
                                bgColor={color}
                                color={'fillWhite'}
                                height={44}
                                onClick={_onCustomButtonClick(b.onClick)}
                            >
                                {b.label}
                            </OkButton>
                        ))}
                        {!hideCancel && (
                            <CancelButton data-exclude-click bgColor="transparent" height={44} onClick={_onCancel}>
                                {cancelLabel ?? t('action.cancel', 'Cancel')}
                            </CancelButton>
                        )}
                    </Buttons>
                ) : null}
            </AlertCard>
        </Wrapper>
    );
};

export default Alerts;

const Icon = styled(FontAwesomeIcon)<{ col?: keyof ThemeColors }>`
    ${({ col, theme }) => col && `color: ${theme.colors[col]};`}
    font-size: 28px;
`;

const AlertCard = styled.div<{ maxWidth?: Alert['maxWidth'] }>`
    background: ${({ theme }) => theme.colors.fillWhite};
    ${({ theme }) => theme.shadows.high};
    padding: 30px 40px;
    ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}px;`};
`;
const Title = styled.h3`
    font-size: 16px;
    margin: 0;
`;

const ContentWrapper = styled(Flex)``;

const Description = styled.p`
    font-size: 14px;
    margin: 5px 0 0 0;
    white-space: pre-line;
`;

const Buttons = styled(Flex)<{ stretchButtons?: boolean }>`
    margin-top: 10px;

    > *:not(:first-child) {
        margin-left: 8px;
    }

    ${({ stretchButtons }) =>
        stretchButtons &&
        `
  > button { flex: 1; }
  `}
`;

const OkButton = styled(Button)``;

const CancelButton = styled(Button)`
    &:hover {
        background-color: ${({ theme }) => theme.colors.fill};
    }
`;

const Wrapper = styled(Flex)`
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
`;

const FullSize = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior: contain;
`;

const Overlay = styled.div`
  /* background: ${({ theme }) => theme.colors.fillBlack}; */
  width: 100%;
  height: 100%;
  /* opacity: 0.4; */
  pointer-events: none;
  cursor: default;
  backdrop-filter: blur(10px);
  overflow: hidden;
  overscroll-behavior: contain;
`;

const CustomerServiceWrapper = styled.div`
    border-top: 1px solid ${({ theme }) => theme.colors.borderGray};
`;
