import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ThunkConf, isFulfilledAction, isPendingAction } from 'store/thunks';
import { RootState } from 'store';
import {
    CartInput,
    CartSkuComment,
    CartSummary,
    CartUpdateCartOrderCheckoutPOSTRequest,
    CartUpdateCartPOSTRequest,
    OrderCartCheckoutWithAttestation,
    OrderSearchOrdersRequest,
    ShopCustomerOrderOverviewPageInfo,
    StandardCheckout,
} from 'microshop-api';
import services from 'services';
import createDebouncedAsyncThunk from 'store/createDebouncedAsyncThunk';
import { createDate } from 'utils/dates';
import { ChangeAttestationExtended, GET_ATTESTATION, attestationSet } from './attestationSlice';

export type CartUpdateSkuQuantity = {
    sku: string;
    quantity: number;
};
export type CartUpdateAddonQuantity = {
    sku: string;
    quantity: number;
    addons: { id: number; quantity: number }[];
};

export type CartUpdateInputQuantity = {
    sku: string;
    quantity: number;
    collection: number;
    split: string | undefined;
    splitId: number | undefined;
    inputs: CartInput[];
};

type CartUpdates = {
    skuQuantity: Record<string, CartUpdateSkuQuantity>;
    addonQuantity: Record<string, CartUpdateAddonQuantity>;
    inputQuantity: Record<string, CartUpdateInputQuantity>;
    comments: Record<string, CartSkuComment>;
};

type InitialState = {
    orders: ShopCustomerOrderOverviewPageInfo | null;
    order: OrderCartCheckoutWithAttestation | null;
    loading: boolean;
    selectedOrder: string | null;
    orderLoading: boolean;
    skuComments: CartSkuComment[];
    editEnabled: boolean;
    checkout: StandardCheckout | undefined;
    cartUpdates: CartUpdates;
    search: OrderSearchOrdersRequest;
    skusToBeRemoved: string[];
};

const initialState: InitialState = {
    orders: null,
    order: null,
    loading: false,
    selectedOrder: null,
    orderLoading: false,
    skuComments: [],
    editEnabled: false,
    checkout: undefined,
    cartUpdates: {
        skuQuantity: {},
        addonQuantity: {},
        inputQuantity: {},
        comments: {},
    },
    skusToBeRemoved: [],
    search: {
        from: createDate(0, -6),
        to: createDate(0),
        page: 1,
        pageSize: 10,
    },
};

export const getOrders = createAsyncThunk<ShopCustomerOrderOverviewPageInfo, void, ThunkConf>(
    'order/getOrders',
    async (_, { dispatch, getState }) => {
        const search = getState().orders.search;
        const response = await services.order.orderSearchOrders(search);
        return response;
    },
);

export const searchOrders = createDebouncedAsyncThunk<void, void>(
    'order/searchOrders',
    async (_, { dispatch, getState }) => {
        dispatch(getOrders());
    },
    600,
);

export const getOrder = createAsyncThunk<OrderCartCheckoutWithAttestation, number, ThunkConf>(
    'order/getOrder',
    async (orderId, { dispatch }) => {
        const result = await services.order.orderGetOrder({ orderId: orderId });
        dispatch(attestationSet(result.attestation));

        return result;
    },
);

export const cartUpdateCartPost = createAsyncThunk<CartSummary, CartUpdateCartPOSTRequest, ThunkConf>(
    'order/cartUpdateCartPost',
    async (cartUpdate, { dispatch }) => {
        const result = await services.order.cartUpdateCartPOST(cartUpdate);
        return result;
    },
);

export const cartUpdateCartOrderCheckoutPost = createAsyncThunk<
    void,
    CartUpdateCartOrderCheckoutPOSTRequest,
    ThunkConf
>('order/cartUpdateCartOrderCheckoutPost', async (checkout, { dispatch }) => {
    await services.order.cartUpdateCartOrderCheckoutPOST(checkout);
});

export const saveOrder = createAsyncThunk<void, number, ThunkConf>(
    'order/saveOrder',
    async (id, { dispatch, getState }) => {
        if (!id) return;

        const state = getState();

        const { cartUpdates, skusToBeRemoved } = state.orders;
        const { addonQuantity, inputQuantity, skuQuantity, comments } = cartUpdates;

        const request: CartUpdateCartPOSTRequest = {
            cartId: id,
            cartChanges: {
                skuAdds: [
                    ...Object.values(addonQuantity),
                    ...Object.values(inputQuantity),
                    ...Object.values(skuQuantity),
                ],
                skuComments: Object.values(comments),
                skusToRemove: skusToBeRemoved,
            },
        };

        dispatch(cartUpdateCartPost(request)).then((res) => {
            if (res.type.includes('fulfilled')) {
                dispatch(getOrders());
            }
        });
        dispatch(
            cartUpdateCartOrderCheckoutPost({
                cartId: id,
                checkout: { standardCheckout: state.orders.checkout },
            }),
        );
    },
);

const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        editEnabledSet: (state, action: PayloadAction<boolean>) => {
            state.editEnabled = action.payload;
        },
        selectedorderSet: (state, action) => {
            state.selectedOrder = action.payload;
        },
        orderSet: (state, action) => {
            state.order = action.payload;
            state.cartUpdates = {
                skuQuantity: {},
                addonQuantity: {},
                inputQuantity: {},
                comments: {},
            };
            state.checkout = state.order?.checkout?.standardCheckout;
            state.editEnabled = false;
            state.skusToBeRemoved = [];
        },
        skuCommentsSet: (state, action: PayloadAction<CartSkuComment[]>) => {
            state.skuComments = action.payload;
        },
        checkoutSet: (
            state,
            action: PayloadAction<{ name: keyof StandardCheckout; value: string | boolean | undefined }>,
        ) => {
            if (
                !state?.checkout ||
                action?.payload?.name === undefined ||
                !action?.payload?.name ||
                action?.payload?.value === undefined
            )
                return;
            (state.checkout[action.payload.name] as string | boolean | null) = action.payload.value;
        },
        checkoutInit: (state, action: PayloadAction<StandardCheckout | undefined>) => {
            state.checkout = action.payload;
        },
        cartUpdatesSet: (state, action: PayloadAction<CartUpdates>) => {
            state.cartUpdates = action.payload;
        },
        searchSet: (state, action: PayloadAction<OrderSearchOrdersRequest>) => {
            state.search = action.payload;
        },
        skusToBeRemovedSet: (state, action: PayloadAction<string[]>) => {
            state.skusToBeRemoved = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getOrders.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getOrders.fulfilled, (state, action) => {
            state.orders = action.payload;
            state.loading = false;
        });
        builder.addCase(searchOrders.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getOrder.pending, (state) => {
            state.orderLoading = true;
        });
        builder.addCase(getOrder.fulfilled, (state, action) => {
            state.orderLoading = false;
            state.order = action.payload;
            state.cartUpdates = {
                skuQuantity: {},
                addonQuantity: {},
                inputQuantity: {},
                comments: {},
            };
            state.editEnabled = false;
            state.checkout = state.order?.checkout?.standardCheckout;
            state.skusToBeRemoved = [];
        });
        builder.addCase(cartUpdateCartPost.pending, (state) => {
            state.orderLoading = true;
        });
        builder.addCase(cartUpdateCartPost.fulfilled, (state, action: PayloadAction<CartSummary>) => {
            if (!state.order) return;
            if (!!action.payload.items?.length) {
                state.order.cart = action.payload;
            } else {
                state.order = null;
            }
            state.editEnabled = false;
            state.orderLoading = false;
        });

        builder.addMatcher(isPendingAction(GET_ATTESTATION), (state) => {
            state.checkout = undefined;
            state.order = null;
            state.editEnabled = false;
        });
        builder.addMatcher(
            isFulfilledAction(GET_ATTESTATION),
            (state, action: PayloadAction<ChangeAttestationExtended>) => {
                if (!action.payload || !action.payload.data || !('cart' in action.payload.data)) return;

                state.checkout = action.payload.data.checkout?.standardCheckout;
                state.order = action.payload.data;
            },
        );
    },
});

// SELECTORS
export const selectOrders = (state: RootState) => state.orders.orders;
export const selectOrder = (state: RootState) => state.orders.order;
export const selectSelectedOrder = (state: RootState) => state.orders.selectedOrder;
export const selectOrdersLoading = (state: RootState) => state.orders.loading;
export const selectOrderLoading = (state: RootState) => state.orders.orderLoading;
export const selectEditEnabled = (state: RootState) => state.orders.editEnabled;
export const selectCheckout = (state: RootState) => state.orders.checkout;
export const selectSearch = (state: RootState) => state.orders.search;
export const selectCartUpdates = (state: RootState) => state.orders.cartUpdates;
export const selectSkusToBeRemoved = (state: RootState) => state.orders.skusToBeRemoved;

export const {
    selectedorderSet,
    orderSet,
    editEnabledSet,
    skuCommentsSet,
    checkoutSet,
    checkoutInit,
    searchSet,
    cartUpdatesSet,
    skusToBeRemovedSet,
} = orderSlice.actions;
export default orderSlice.reducer;
