"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShopAttestationStatusToJSON = exports.ShopAttestationStatusFromJSONTyped = exports.ShopAttestationStatusFromJSON = exports.ShopAttestationStatus = void 0;
/**
 *
 * @export
 */
exports.ShopAttestationStatus = {
    Pending: 0,
    Approved: 1,
    Rejected: 2
};
function ShopAttestationStatusFromJSON(json) {
    return ShopAttestationStatusFromJSONTyped(json, false);
}
exports.ShopAttestationStatusFromJSON = ShopAttestationStatusFromJSON;
function ShopAttestationStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.ShopAttestationStatusFromJSONTyped = ShopAttestationStatusFromJSONTyped;
function ShopAttestationStatusToJSON(value) {
    return value;
}
exports.ShopAttestationStatusToJSON = ShopAttestationStatusToJSON;
