"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShopCustomerOrderOverviewPageInfoToJSON = exports.ShopCustomerOrderOverviewPageInfoFromJSONTyped = exports.ShopCustomerOrderOverviewPageInfoFromJSON = exports.instanceOfShopCustomerOrderOverviewPageInfo = void 0;
var runtime_1 = require("../runtime");
var ShopCustomerOrderOverview_1 = require("./ShopCustomerOrderOverview");
/**
 * Check if a given object implements the ShopCustomerOrderOverviewPageInfo interface.
 */
function instanceOfShopCustomerOrderOverviewPageInfo(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfShopCustomerOrderOverviewPageInfo = instanceOfShopCustomerOrderOverviewPageInfo;
function ShopCustomerOrderOverviewPageInfoFromJSON(json) {
    return ShopCustomerOrderOverviewPageInfoFromJSONTyped(json, false);
}
exports.ShopCustomerOrderOverviewPageInfoFromJSON = ShopCustomerOrderOverviewPageInfoFromJSON;
function ShopCustomerOrderOverviewPageInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'pageSize': !(0, runtime_1.exists)(json, 'pageSize') ? undefined : json['pageSize'],
        'currentPage': !(0, runtime_1.exists)(json, 'currentPage') ? undefined : json['currentPage'],
        'totalItems': !(0, runtime_1.exists)(json, 'totalItems') ? undefined : json['totalItems'],
        'totalPages': !(0, runtime_1.exists)(json, 'totalPages') ? undefined : json['totalPages'],
        'items': !(0, runtime_1.exists)(json, 'items') ? undefined : (json['items'] === null ? null : json['items'].map(ShopCustomerOrderOverview_1.ShopCustomerOrderOverviewFromJSON)),
        'hasNextPage': !(0, runtime_1.exists)(json, 'hasNextPage') ? undefined : json['hasNextPage'],
        'hasPreviousPage': !(0, runtime_1.exists)(json, 'hasPreviousPage') ? undefined : json['hasPreviousPage'],
    };
}
exports.ShopCustomerOrderOverviewPageInfoFromJSONTyped = ShopCustomerOrderOverviewPageInfoFromJSONTyped;
function ShopCustomerOrderOverviewPageInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'pageSize': value.pageSize,
        'currentPage': value.currentPage,
        'totalItems': value.totalItems,
        'totalPages': value.totalPages,
        'items': value.items === undefined ? undefined : (value.items === null ? null : value.items.map(ShopCustomerOrderOverview_1.ShopCustomerOrderOverviewToJSON)),
    };
}
exports.ShopCustomerOrderOverviewPageInfoToJSON = ShopCustomerOrderOverviewPageInfoToJSON;
