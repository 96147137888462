import Text from '../ui/Text';
import styled from 'styled-components';
import { OrderCartCheckoutWithAttestation, StandardCheckout } from 'microshop-api';
import Input from 'components/ui/Input';
import Flex from 'components/ui/Flex';
import { ChangeEvent, useState } from 'react';
import useAppSelector from 'hooks/useAppSelector';
import { checkoutSet, selectCheckout } from 'store/reducers/orderSlice';
import useAppDispatch from 'hooks/useAppDispatch';

type DeliveryProps = {
    editEnabled: boolean;
    currentOrder: OrderCartCheckoutWithAttestation;
};

export const Delivery = ({ editEnabled }: DeliveryProps) => {
    const dispatch = useAppDispatch();
    const checkout = useAppSelector(selectCheckout);

    if (!checkout) return null;

    const {
        address1,
        address2,
        postalCode,
        city,
        country,
        firstName,
        lastName,
        email,
        phone,
        comment,
        emailLocked,
    } = checkout;

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        dispatch(checkoutSet({ name: name as keyof StandardCheckout, value }));
    };

    return (
        <>
            <DeliveryInfo className="mb-3">
                {!editEnabled && (
                    <>
                        <h3>Leveransadress</h3>
                        {firstName && lastName && <Text>{`${firstName} ${lastName}`}</Text>}
                        {email && <Text>{email}</Text>}
                        {phone && <Text>{phone}</Text>}
                        {address1 && <Text>{address1}</Text>}
                        {country && <Text>{country}</Text>}
                        {postalCode && <Text>{postalCode}</Text>}
                        {city && <Text>{city}</Text>}
                        {comment && <Text>{comment}</Text>}
                    </>
                )}
                {editEnabled && (
                    <>
                        <Flex $gap={16}>
                            <StyledInput
                                type="text"
                                name="firstName"
                                label="firstName"
                                className="mb-2"
                                defaultValue={firstName ?? ''}
                                onChange={(e) => handleChange(e)}
                            />
                            <StyledInput
                                type="text"
                                name="lastName"
                                label="lastName"
                                className="mb-2"
                                defaultValue={lastName ?? ''}
                                onChange={(e) => handleChange(e)}
                            />
                        </Flex>
                        <Flex $gap={16}>
                            <StyledInput
                                name="email"
                                label="Email"
                                disabled={emailLocked}
                                bgColor={emailLocked ? 'borderLight' : 'fillWhite'}
                                className="mb-2"
                                type="email"
                                defaultValue={email ?? ''}
                                onChange={(e) => handleChange(e)}
                            />
                            <StyledInput
                                label="Telefon"
                                name="phone"
                                className="mb-2"
                                type="phone"
                                defaultValue={phone ?? ''}
                                onChange={(e) => handleChange(e)}
                            />
                        </Flex>
                        <Flex $gap={16}>
                            <StyledInput
                                label="Adress1"
                                name="address1"
                                className="mb-2"
                                type="text"
                                defaultValue={address1 ?? ''}
                                onChange={(e) => handleChange(e)}
                            />
                            <StyledInput
                                label="Land"
                                name="country"
                                type="text"
                                className="mb-2"
                                defaultValue={country ?? ''}
                                onChange={(e) => handleChange(e)}
                            />
                        </Flex>
                        <Flex $gap={16}>
                            <StyledInput
                                label="Postnummer"
                                name="postalCode"
                                className="mb-2"
                                type="text"
                                defaultValue={postalCode ?? ''}
                                onChange={(e) => handleChange(e)}
                            />
                            <StyledInput
                                label="Stad"
                                name="city"
                                className="mb-2"
                                type="text"
                                defaultValue={city ?? ''}
                                onChange={(e) => handleChange(e)}
                            />
                        </Flex>
                        <Input
                            label="Kommentar"
                            name="comment"
                            className="mb-2"
                            type="text"
                            defaultValue={comment ?? ''}
                            onChange={(e) => handleChange(e)}
                        />
                    </>
                )}
            </DeliveryInfo>
        </>
    );
};
const DeliveryInfo = styled.div`
    background-color: #ffff;
    padding: 20px;
`;

const StyledInput = styled(Input)`
    width: 100%;
    flex: 1;
`;
