"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BudgetChangeAttestationToJSON = exports.BudgetChangeAttestationFromJSONTyped = exports.BudgetChangeAttestationFromJSON = exports.instanceOfBudgetChangeAttestation = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the BudgetChangeAttestation interface.
 */
function instanceOfBudgetChangeAttestation(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfBudgetChangeAttestation = instanceOfBudgetChangeAttestation;
function BudgetChangeAttestationFromJSON(json) {
    return BudgetChangeAttestationFromJSONTyped(json, false);
}
exports.BudgetChangeAttestationFromJSON = BudgetChangeAttestationFromJSON;
function BudgetChangeAttestationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'newBudget': !(0, runtime_1.exists)(json, 'newBudget') ? undefined : json['newBudget'],
        'oldBudget': !(0, runtime_1.exists)(json, 'oldBudget') ? undefined : json['oldBudget'],
    };
}
exports.BudgetChangeAttestationFromJSONTyped = BudgetChangeAttestationFromJSONTyped;
function BudgetChangeAttestationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'newBudget': value.newBudget,
        'oldBudget': value.oldBudget,
    };
}
exports.BudgetChangeAttestationToJSON = BudgetChangeAttestationToJSON;
