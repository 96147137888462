import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import { useEffect, useState } from 'react';
import {
    attest,
    getAttestation,
    getAttestations,
    isBudgetChangeAttestation,
    isOrderCartCheckoutWithAttestation,
    selectAttestLoading,
    selectAttestationLoading,
    selectAttestations,
    selectCurrentAttestation,
    selectSelectedAttestation,
    selectedAttestationSet,
} from 'store/reducers/attestationSlice';
import Text from 'components/ui/Text';
import styled from 'styled-components';
import Flex from 'components/ui/Flex';
import { ChangeAttestation, ShopAttestationOverview, ShopAttestationStatus, ShopAttestationType } from 'microshop-api';
import Button from 'components/ui/Button';
import Pagination from 'components/ui/Pagination';
import { useTranslation } from 'react-i18next';
import Input from 'components/ui/Input';
import useBreakPoints from 'hooks/useBreakPoints';
import Table, { TableCell, TableRow } from 'components/ui/Table';
import { TFunction } from 'i18next';
import { Order } from 'components/Order/Order';
import { selectEditEnabled, selectOrderLoading } from 'store/reducers/orderSlice';
import { LoadingWrapper } from './Orders';
import Spinner from 'components/ui/Spinner';
import { selectUserInfo } from 'store/reducers/shopSlice';

export const Attestations = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const attestations = useAppSelector(selectAttestations);
    const currentAttestation = useAppSelector(selectCurrentAttestation);
    const attestationLoading = useAppSelector(selectAttestationLoading);
    const attestLoading = useAppSelector(selectAttestLoading);
    const selectedAttestation = useAppSelector(selectSelectedAttestation);
    const orderLoading = useAppSelector(selectOrderLoading);
    const [selectedAttestationId, setSelectedAttestationId] = useState<string | null>(null);
    const editEnabled = useAppSelector(selectEditEnabled);
    const isAuthenticated = useAppSelector(selectUserInfo)?.isAuthenticated;

    const bp = useBreakPoints();

    const selectedAttestationFromList = attestations?.items?.find(
        (attestation) => attestation.id === selectedAttestationId,
    );

    useEffect(() => {
        dispatch(getAttestations({ page: 1, pageSize: 10 }));
    }, []);

    if (!attestations || !attestations?.items?.length) {
        return <h3 className="m-4">{t('no_attestation_requests_found')}</h3>;
    }

    const tableHeaders = ['Id', t('type'), t('user'), t('date')];

    const handleOnClick = (id: string) => {
        dispatch(selectedAttestationSet(id));
        dispatch(getAttestation(id));
        setSelectedAttestationId(id);
    };

    if (isOrderCartCheckoutWithAttestation(currentAttestation?.data, ShopAttestationType.Order)) {
        console.log(currentAttestation?.data);
    }

    if (!isAuthenticated) return null;

    return (
        <div className="mx-2 my-2">
            <Flex>
                <h2>{t('attestation')}</h2>
            </Flex>
            <Flex column={bp.lgBelow} className="mx-2 my-2" $gap={20}>
                <Left style={{ width: '100%' }}>
                    <Table
                        className="mx-2 my-4"
                        compact={bp.xlBelow}
                        arrow
                        rowLayout="auto"
                        headers={tableHeaders}
                        cols={tableHeaders.length}
                        tintOddRows
                        rows={
                            attestations?.items
                                ? getAttestationRows(attestations?.items, handleOnClick, t, selectedAttestationId)
                                : []
                        }
                    />
                    <Pagination
                        page={attestations?.currentPage!}
                        pages={attestations?.totalPages!}
                        pageSize={attestations?.pageSize!}
                        itemCount={attestations?.items?.length!}
                        itemsTotal={attestations?.totalItems!}
                        label={''}
                        setPage={(page: number) => dispatch(getAttestations({ page, pageSize: 10 }))}
                        compact={false}
                    />
                </Left>
                {!!selectedAttestation && !!currentAttestation && (
                    <Right>
                        {orderLoading ? (
                            <LoadingWrapper>
                                <Spinner center />
                            </LoadingWrapper>
                        ) : (
                            <>
                                <h3 className="mx-3">{currentAttestation?.id}</h3>
                                <AttestationDetails
                                    currentAttestation={currentAttestation}
                                    attestationLoading={attestationLoading}
                                    selectedAttestationFromList={selectedAttestationFromList}
                                    editEnabled={editEnabled}
                                >
                                    <Order
                                        orderDetailsOpen={true}
                                        setOrderDetailsOpen={() => {}}
                                        removePadding
                                        editDisabled={currentAttestation.status !== ShopAttestationStatus.Pending}
                                    />
                                </AttestationDetails>
                            </>
                        )}
                    </Right>
                )}
            </Flex>
        </div>
    );
};

const getAttestationRows = (
    attestations: ShopAttestationOverview[],
    handleOnClick: (id: string) => void,
    t: TFunction<'translation', undefined, 'translation'>,
    selectedAttestationId: string | null,
): TableRow[] => {
    return attestations.map(
        (attestation): TableRow => {
            const cells: TableCell[] = [
                <div>{attestation.id}</div>,
                <div>{typeof attestation.type === 'number' && getAttestationDescription(attestation?.type, t)}</div>,
                <div>{attestation?.requestedBy}</div>,
                <div>{attestation?.dateCreated?.str ?? ''}</div>,
            ].map((el) => ({ el }));
            return {
                cells: cells,
                onClick: () => handleOnClick(attestation.id ?? ''),
                selected: attestation.id === selectedAttestationId,
                bgSelected: 'borderDark',
            };
        },
    );
};

export const AttestationDetails = ({
    selectedAttestationFromList,
    currentAttestation,
    attestationLoading,
    children,
    editEnabled,
}: {
    selectedAttestationFromList?: ShopAttestationOverview | undefined;
    currentAttestation: ChangeAttestation | undefined;
    attestationLoading: boolean;
    children?: React.ReactNode;
    editEnabled: boolean;
}) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [comment, setComment] = useState(currentAttestation?.comment ?? '');

    useEffect(() => {
        setComment(currentAttestation?.comment ?? '');
    }, [currentAttestation]);

    if (attestationLoading)
        return (
            <LoadingWrapper>
                <Spinner center />
            </LoadingWrapper>
        );

    return (
        <AttestationDetailsWrapper>
            {!!currentAttestation ? (
                <div>
                    {typeof currentAttestation?.type === 'number' && (
                        <Text className="mb-2" bold>
                            {getAttestationDescription(currentAttestation.type, t)}
                        </Text>
                    )}

                    <Flex justify="between" $gap={14}>
                        <Box className="mb-3" style={{ flex: 1 }}>
                            {!!selectedAttestationFromList?.requestedBy && (
                                <>
                                    <Text bold>{t('user')}:</Text>
                                    <Text className="mb-2">{selectedAttestationFromList?.requestedBy}</Text>
                                </>
                            )}{' '}
                            {!!currentAttestation?.dateCreated && (
                                <>
                                    <Text bold>{t('date')}:</Text>
                                    <Text>{currentAttestation.dateCreated.str}</Text>
                                </>
                            )}
                        </Box>

                        {currentAttestation?.type !== undefined &&
                            currentAttestation?.type !== null &&
                            isBudgetChangeAttestation(currentAttestation?.data, currentAttestation?.type) && (
                                <Box className="mb-3" style={{ flex: 1 }}>
                                    <div>
                                        <div>
                                            <Text bold>{t('earlierPricePool')}:</Text>
                                            <Text>{currentAttestation?.data?.oldBudget}</Text>
                                        </div>
                                        <div>
                                            <Text bold className="mb-1">
                                                {t('newPricePool')}:
                                            </Text>
                                            <Text>{currentAttestation?.data?.newBudget}</Text>
                                        </div>
                                    </div>
                                </Box>
                            )}
                    </Flex>
                    {children}
                    <Box>
                        {currentAttestation?.status !== undefined && currentAttestation?.status !== null && (
                            <Flex $gap={2} className="mb-3">
                                <Text bold>{t('status')}:</Text>{' '}
                                <Text>{getAttestationStatus(currentAttestation.status, t)}</Text>
                            </Flex>
                        )}
                        <Text bold className="mb-1">
                            {t('comment')}:
                        </Text>
                        <div className="mt-2">
                            <Input
                                type="textarea"
                                placeholder={t('comment') + '...'}
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                            />
                        </div>
                        <Flex $gap={12} className="mt-3">
                            <Button
                                style={{ backgroundColor: '#1A67BB' }}
                                color="textWhite"
                                disabled={editEnabled || currentAttestation.status !== ShopAttestationStatus.Pending}
                                onClick={() => {
                                    dispatch(
                                        attest({
                                            attestAttestation: {
                                                attestationId: currentAttestation.id,
                                                comment: comment,
                                                status: ShopAttestationStatus.Approved,
                                            },
                                        }),
                                    );
                                    setComment('');
                                }}
                            >
                                {t('approve')}
                            </Button>
                            <Button
                                disabled={editEnabled || currentAttestation.status !== ShopAttestationStatus.Pending}
                                onClick={() => {
                                    dispatch(
                                        attest({
                                            attestAttestation: {
                                                attestationId: currentAttestation.id,
                                                comment: comment,
                                                status: ShopAttestationStatus.Rejected,
                                            },
                                        }),
                                    );
                                    setComment('');
                                }}
                            >
                                {t('deny')}
                            </Button>
                        </Flex>
                    </Box>
                </div>
            ) : (
                <Text color="textFaded" fontSize={22}>
                    {t('choseAttest')}
                </Text>
            )}
        </AttestationDetailsWrapper>
    );
};

// const Circle = styled.div`
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     width: 34px;
//     height: 34px;
//     padding: 12px;
//     border-radius: 24px;
//     background-color: #ffffff;
// `;

const AttestationDetailsWrapper = styled.div`
    flex: 1;
    width: 100%;
    padding: 18px;
    box-shadow: -17px 0px 4px -17px rgba(0, 0, 0, 0.17);
`;

const Left = styled.div`
    flex: 2;
    ${({ theme }) => theme.media.xlBelow} {
        flex: 1;
    }
`;

const Right = styled.div`
    flex: 3;
    ${({ theme }) => theme.media.xlBelow} {
        flex: 2;
    }
`;

const Box = styled.div`
    background-color: #ffff;
    padding: 20px;
`;

function getAttestationDescription(type: ShopAttestationType, t: TFunction<'translation', undefined, 'translation'>) {
    switch (type) {
        case ShopAttestationType.ChangeBudget:
            return t('update_attestation_type_price');
        case ShopAttestationType.ChangeSize:
            return t('update_attestation_type_size');
        case ShopAttestationType.Order:
            return t('update_attestation_type_order');
        default:
            return null;
    }
}

function getAttestationStatus(status: ShopAttestationStatus, t: TFunction<'translation', undefined, 'translation'>) {
    switch (status) {
        case ShopAttestationStatus.Pending:
            return t('pending');
        case ShopAttestationStatus.Approved:
            return t('approved');
        case ShopAttestationStatus.Rejected:
            return t('rejected');
        default:
            return null;
    }
}
