"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CartChangesToJSON = exports.CartChangesFromJSONTyped = exports.CartChangesFromJSON = exports.instanceOfCartChanges = void 0;
var runtime_1 = require("../runtime");
var CartSkuAdd_1 = require("./CartSkuAdd");
var CartSkuComment_1 = require("./CartSkuComment");
/**
 * Check if a given object implements the CartChanges interface.
 */
function instanceOfCartChanges(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfCartChanges = instanceOfCartChanges;
function CartChangesFromJSON(json) {
    return CartChangesFromJSONTyped(json, false);
}
exports.CartChangesFromJSON = CartChangesFromJSON;
function CartChangesFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'skuAdds': !(0, runtime_1.exists)(json, 'skuAdds') ? undefined : (json['skuAdds'] === null ? null : json['skuAdds'].map(CartSkuAdd_1.CartSkuAddFromJSON)),
        'skuComments': !(0, runtime_1.exists)(json, 'skuComments') ? undefined : (json['skuComments'] === null ? null : json['skuComments'].map(CartSkuComment_1.CartSkuCommentFromJSON)),
        'skusToRemove': !(0, runtime_1.exists)(json, 'skusToRemove') ? undefined : json['skusToRemove'],
    };
}
exports.CartChangesFromJSONTyped = CartChangesFromJSONTyped;
function CartChangesToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'skuAdds': value.skuAdds === undefined ? undefined : (value.skuAdds === null ? null : value.skuAdds.map(CartSkuAdd_1.CartSkuAddToJSON)),
        'skuComments': value.skuComments === undefined ? undefined : (value.skuComments === null ? null : value.skuComments.map(CartSkuComment_1.CartSkuCommentToJSON)),
        'skusToRemove': value.skusToRemove,
    };
}
exports.CartChangesToJSON = CartChangesToJSON;
