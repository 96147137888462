import { AnyAction, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from 'store';

const hasPrefix = (action: AnyAction, prefix: string) => action.type.startsWith(prefix);
const isPending = (action: AnyAction) => action.type.endsWith('/pending');
const isFulfilled = (action: AnyAction) => action.type.endsWith('/fulfilled');
const isRejected = (action: AnyAction) => action.type.endsWith('/rejected');

export const isPendingAction = (prefix: string) => (action: AnyAction): action is AnyAction => {
    return hasPrefix(action, prefix) && isPending(action);
};

export const isRejectedAction = (prefix: string) => (action: AnyAction): action is AnyAction => {
    return hasPrefix(action, prefix) && isRejected(action);
};

export const isFulfilledAction = <T = any>(prefix: string) => (
    action: PayloadAction<T>,
): action is PayloadAction<T> => {
    return hasPrefix(action, prefix) && isFulfilled(action);
};

export type ThunkConf = {
    dispatch: AppDispatch;
    state: RootState;
};
