"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShopAttestationTypeToJSON = exports.ShopAttestationTypeFromJSONTyped = exports.ShopAttestationTypeFromJSON = exports.ShopAttestationType = void 0;
/**
 *
 * @export
 */
exports.ShopAttestationType = {
    ChangeBudget: 0,
    ChangeSize: 1,
    Order: 2
};
function ShopAttestationTypeFromJSON(json) {
    return ShopAttestationTypeFromJSONTyped(json, false);
}
exports.ShopAttestationTypeFromJSON = ShopAttestationTypeFromJSON;
function ShopAttestationTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.ShopAttestationTypeFromJSONTyped = ShopAttestationTypeFromJSONTyped;
function ShopAttestationTypeToJSON(value) {
    return value;
}
exports.ShopAttestationTypeToJSON = ShopAttestationTypeToJSON;
