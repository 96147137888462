import Spinner from 'components/ui/Spinner';
import Table, { TableCell, TableRow } from 'components/ui/Table';
import useAppDispatch from 'hooks/useAppDispatch';
import useAppSelector from 'hooks/useAppSelector';
import useBreakPoints from 'hooks/useBreakPoints';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    getOrder,
    getOrders,
    searchOrders,
    searchSet,
    selectEditEnabled,
    selectOrder,
    selectOrderLoading,
    selectOrders,
    selectOrdersLoading,
    selectSearch,
    selectSelectedOrder,
    selectedorderSet,
} from 'store/reducers/orderSlice';
import styled from 'styled-components';
import Text from 'components/ui/Text';
import Flex from 'components/ui/Flex';
import { OrderFilter } from 'components/OrderFilter';
import { Order } from 'components/Order/Order';
import { ShopCustomerOrderOverview } from 'microshop-api';
import Pagination from 'components/ui/Pagination';
import { AttestationDetails } from './Attestations';
import { selectAttestationLoading, selectCurrentAttestation } from 'store/reducers/attestationSlice';
import { selectSelectedShopUnitInfo } from 'store/reducers/userSlice';
import { selectUserInfo } from 'store/reducers/shopSlice';

export const Orders = () => {
    const dispatch = useAppDispatch();
    const bp = useBreakPoints();
    const { t } = useTranslation();
    const orders = useAppSelector(selectOrders);
    const selectedOrder = useAppSelector(selectSelectedOrder);
    const currentOrder = useAppSelector(selectOrder);
    const loading = useAppSelector(selectOrdersLoading);
    const currentAttestation = useAppSelector(selectCurrentAttestation);
    const attestationLoading = useAppSelector(selectAttestationLoading);
    const editEnabled = useAppSelector(selectEditEnabled);
    const orderLoading = useAppSelector(selectOrderLoading);
    const search = useAppSelector(selectSearch);
    const selectedShopUnitInfo = useAppSelector(selectSelectedShopUnitInfo);
    const isAuthenticated = useAppSelector(selectUserInfo)?.isAuthenticated;

    const [orderDetailsOpen, setOrderDetailsOpen] = useState(true);
    const ref = useRef<HTMLDivElement>(null);

    const isAttestant = selectedShopUnitInfo?.isAttestent;

    useEffect(() => {
        if (!orders) {
            dispatch(getOrders());
            return;
        }

        dispatch(searchOrders());
    }, [search]);

    const tableHeaders = [t('order'), t('name'), t('created'), t('number'), t('total')];

    const handleOnClick = (orderNumber: string | null | undefined, id: number | undefined) => {
        dispatch(selectedorderSet(orderNumber));
        setOrderDetailsOpen(true);
        if (id) {
            dispatch(getOrder(id));
        }

        if (bp.lgBelow) {
            if (ref?.current?.offsetTop === undefined) return;
            const NAVBAR_HEIGHT = 71;
            window.scrollTo({ behavior: 'smooth', top: ref.current?.offsetTop - NAVBAR_HEIGHT });
        }
    };

    if (!isAuthenticated) return null;

    return (
        <>
            <Flex justify="between" align="center" className="mx-2 my-2">
                <h2>{t('orders')}</h2>
            </Flex>

            <Flex column={bp.lgBelow} className="mx-2 my-2" $gap={20}>
                <Left style={{ width: '100%' }}>
                    <OrderFilter />
                    {loading && (
                        <LoadingWrapper>
                            <Spinner center />
                        </LoadingWrapper>
                    )}
                    {!loading && !!orders?.items?.length && (
                        <>
                            <Table
                                className="mx-2 my-4"
                                compact={bp.xlBelow}
                                rowLayout="auto"
                                headers={tableHeaders}
                                cols={tableHeaders.length}
                                tintOddRows
                                rows={orders?.items ? getOrderRows(orders?.items, handleOnClick, selectedOrder) : []}
                            />
                            <Pagination
                                page={orders.currentPage!}
                                pages={orders.totalPages!}
                                pageSize={orders.pageSize!}
                                itemCount={orders.items.length!}
                                itemsTotal={orders.totalItems!}
                                label={''}
                                setPage={(page: number) => {
                                    dispatch(searchSet({ ...search, page: page }));
                                }}
                                compact={false}
                            />
                        </>
                    )}
                    {!loading && !orders?.items?.length && <Text center>{t('noOrders')}</Text>}
                </Left>

                {selectedOrder && orderDetailsOpen && !!currentOrder?.cart && (
                    <Right ref={ref}>
                        {orderLoading ? (
                            <LoadingWrapper>
                                <Spinner center />
                            </LoadingWrapper>
                        ) : isAttestant && !!currentOrder?.attestation ? (
                            <>
                                <h3 className="mx-3">{selectedOrder}</h3>
                                <AttestationDetails
                                    currentAttestation={currentAttestation}
                                    attestationLoading={attestationLoading}
                                    editEnabled={editEnabled}
                                >
                                    <Order
                                        removePadding
                                        setOrderDetailsOpen={setOrderDetailsOpen}
                                        orderDetailsOpen={orderDetailsOpen}
                                    />
                                </AttestationDetails>
                            </>
                        ) : (
                            <Order setOrderDetailsOpen={setOrderDetailsOpen} orderDetailsOpen={orderDetailsOpen} />
                        )}
                    </Right>
                )}
            </Flex>
        </>
    );
};

const getOrderRows = (
    orders: ShopCustomerOrderOverview[],
    handleOnClick: (orderNumber: string | null | undefined, id: number | undefined) => void,
    selectedOrder: string | null | undefined,
) => {
    return orders?.map(
        (order): TableRow => {
            const cells: TableCell[] = [
                order?.orderNumber ?? '',
                order?.deliveryAdressName ?? '',
                order?.created?.str ?? '',
                order?.quantity,
                `${order?.total} ${order?.currency}`,
            ].map((el) => ({ el }));
            return {
                cells: cells,
                onClick: () => handleOnClick(order.orderNumber, order.id),
                selected: order.orderNumber === selectedOrder,
                bgSelected: 'borderDark',
            };
        },
    );
};

export const LoadingWrapper = styled.div`
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 40px;
`;

const Left = styled.div`
    flex: 2;
    ${({ theme }) => theme.media.xlBelow} {
        flex: 2;
    }
`;

const Right = styled.div`
    flex: 3;
    ${({ theme }) => theme.media.xlBelow} {
        flex: 3;
    }
`;
