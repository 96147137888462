import { useEffect, useState } from 'react';
import { ThemeBreakpoints, useTheme } from 'styled-components';
import { BreakPoint, BreakPointSize } from 'types/styled';
import useWindowSize from './useWindowSize';
import defaultTheme from 'theme/defaultTheme';

type BreakPoints = {
    [key in BreakPoint]: boolean;
};

export type BreakPointsWithWidth = BreakPoints & {
    width: number;
};

export default function useBreakPoints(forceMaxSize?: BreakPointSize): BreakPointsWithWidth {
    const windowSize = useWindowSize();
    const [bp, setBp] = useState<BreakPointsWithWidth>(getBreakPoints(window.innerWidth, forceMaxSize));

    useEffect(() => {
        const bpts = getBreakPoints(window.innerWidth, forceMaxSize);
        if (JSON.stringify(bpts) !== JSON.stringify(bp)) {
            setBp(bpts);
        }
    }, [forceMaxSize, windowSize]);

    return bp;
}

function getBreakPoints(windowWidth: number, forceMaxSize?: BreakPointSize): BreakPointsWithWidth {
    const width = getWidth(windowWidth, forceMaxSize);
    const themeBps = defaultTheme.breakpoints;
    return {
        min: true,
        xs: width >= themeBps.xs,
        sm: width >= themeBps.sm,
        md: width >= themeBps.md,
        lg: width >= themeBps.lg,
        xl: width >= themeBps.xl,
        xsBelow: width < themeBps.xs,
        smBelow: width < themeBps.sm,
        mdBelow: width < themeBps.md,
        lgBelow: width < themeBps.lg,
        xlBelow: width < themeBps.xl,
        width: windowWidth,
    };
}

// If window width is more than maxSize then return max
function getWidth(windowWidth: number, forceMaxSize?: BreakPointSize) {
    const themeBps = defaultTheme.breakpoints;
    return forceMaxSize && windowWidth > themeBps[forceMaxSize] ? themeBps[forceMaxSize] : windowWidth;
}
