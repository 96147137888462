import {
    AttestationApi,
    AttestationAttestPOSTRequest,
    AttestationCreateBudgetChangeAttestationPOSTRequest,
    AttestationGetAttestationsRequest,
    AttestationProductSizeChangeAttestationRequestPOSTRequest,
    BudgetChangeAttestation,
    ChangeAttestation,
    ProductSizeChangeAttestation,
    ShopAttestationOverviewPageInfo,
} from 'microshop-api';
import { config } from './api';

const attestationApi = new AttestationApi(config);

const attestation = {
    getAttestations: (request: AttestationGetAttestationsRequest): Promise<ShopAttestationOverviewPageInfo> =>
        attestationApi.attestationGetAttestations(request),
    get: (id: string): Promise<ChangeAttestation> => attestationApi.attestationGet({ id }),
    createBudgetAttestation: (
        attestation: AttestationCreateBudgetChangeAttestationPOSTRequest,
    ): Promise<BudgetChangeAttestation> => attestationApi.attestationCreateBudgetChangeAttestationPOST(attestation),
    createProductSiezAttestation: (
        attestation: AttestationProductSizeChangeAttestationRequestPOSTRequest,
    ): Promise<ProductSizeChangeAttestation> =>
        attestationApi.attestationProductSizeChangeAttestationRequestPOST(attestation),
    attest: (attestation: AttestationAttestPOSTRequest): Promise<any> =>
        attestationApi.attestationAttestPOST(attestation),
};

export { attestation };
