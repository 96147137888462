export function formattedDate(date: Date) {
    return `${date.getFullYear()}-${`0${date.getMonth() + 1}`.slice(-2)}-${`0${date.getDate()}`.slice(-2)}`;
}

export function createDate(year: number, month?: number) {
    let date = new Date(Date.now() + 24 * 60 * 60 * 1000);
    date.setFullYear(date.getFullYear() + year);

    if (month) date.setMonth(date.getMonth() + month);

    return date;
}

export function createDateToISOString(year: number, month?: number) {
    return createDate(year, month).toISOString();
}
