import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import IconToast from 'components/ui/IconToast';
import {
    AttestationAttestPOSTRequest,
    AttestationCreateBudgetChangeAttestationPOSTRequest,
    AttestationGetAttestationsRequest,
    AttestationProductSizeChangeAttestationRequestPOSTRequest,
    BudgetChangeAttestation,
    ChangeAttestation,
    OrderCartCheckoutWithAttestation,
    ProductSizeChangeAttestation,
    ShopAttestationOverviewPageInfo,
    ShopAttestationType,
} from 'microshop-api';
import { toast } from 'react-toastify';
import services from 'services';
import { RootState } from 'store';
import { ThunkConf } from 'store/thunks';

type InitialState = {
    attestations: ShopAttestationOverviewPageInfo | null;
    currentAttestation?: ChangeAttestationExtended;
    selectedAttestation: string | null;
    loading: boolean;
    attestationLoading: boolean;
    attestLoading: boolean;
};

const initialState: InitialState = {
    attestations: null,
    currentAttestation: undefined,
    selectedAttestation: null,
    attestationLoading: false,
    loading: false,
    attestLoading: false,
};

export interface ChangeAttestationExtended extends ChangeAttestation {
    data: ProductSizeChangeAttestation | BudgetChangeAttestation | OrderCartCheckoutWithAttestation;
}

export const getAttestations = createAsyncThunk<
    ShopAttestationOverviewPageInfo,
    AttestationGetAttestationsRequest,
    ThunkConf
>('attestation/searchAttestations', async ({ page, pageSize }) => {
    const result = await services.attestation.getAttestations({ page, pageSize });
    return result;
});

export type ChangeAttestationWithOrder = ChangeAttestation & {
    data: OrderCartCheckoutWithAttestation;
};

export const isOrderCartCheckoutWithAttestation = (
    data: any,
    shopAttestationType: ShopAttestationType,
): data is OrderCartCheckoutWithAttestation => {
    return shopAttestationType === ShopAttestationType.Order;
};

export const isBudgetChangeAttestation = (
    data: any,
    shopAttestationType: ShopAttestationType,
): data is BudgetChangeAttestation => {
    return shopAttestationType === ShopAttestationType.ChangeBudget;
};

export const isProductSizeChangeAttestation = (
    data: any,
    shopAttestationType: ShopAttestationType,
): data is ProductSizeChangeAttestation => {
    return shopAttestationType === ShopAttestationType.ChangeSize;
};

export const GET_ATTESTATION = 'attestation/getAttestation';

export const getAttestation = createAsyncThunk<ChangeAttestationExtended, string, ThunkConf>(
    GET_ATTESTATION,
    async (id) => {
        const result = await services.attestation.get(id);
        if (isBudgetChangeAttestation(result.data, result.type!)) {
            return { ...result, data: result.data as BudgetChangeAttestation } as ChangeAttestationExtended;
        }
        if (isProductSizeChangeAttestation(result.data, result?.type!)) {
            return { ...result, data: result.data as ProductSizeChangeAttestation } as ChangeAttestationExtended;
        }
        if (isOrderCartCheckoutWithAttestation(result.data, result.type!)) {
            return { ...result, data: result.data } as ChangeAttestationExtended;
        }
        return result as ChangeAttestationExtended;
    },
);

export const createBudgetAttestation = createAsyncThunk<
    BudgetChangeAttestation,
    AttestationCreateBudgetChangeAttestationPOSTRequest,
    ThunkConf
>('attestation/createBudgetAttestation', async ({ budgetChangeAttestation }) => {
    const result = await services.attestation.createBudgetAttestation({
        budgetChangeAttestation,
    });
    return result;
});

export const createProductSiezAttestation = createAsyncThunk<
    ProductSizeChangeAttestation,
    AttestationProductSizeChangeAttestationRequestPOSTRequest,
    ThunkConf
>('attestation/createProductSiezAttestation', async ({ productSizeChangeAttestation }) => {
    const result = await services.attestation.createProductSiezAttestation({
        productSizeChangeAttestation,
    });
    return result;
});

export const attest = createAsyncThunk<any, AttestationAttestPOSTRequest, ThunkConf>(
    'attestation/attest',
    async (attestAttestation, { dispatch, getState }) => {
        const result = await services.attestation.attest(attestAttestation);
        toast(() => <IconToast preset="success" text="Attestering sparad" />, {
            autoClose: 2000,
        });
        dispatch(currentAttestationSet(attestAttestation));

        return result;
    },
);

const attestationSlice = createSlice({
    name: 'attestation',
    initialState,
    reducers: {
        currentAttestationSet: (state, action: PayloadAction<AttestationAttestPOSTRequest>) => {
            if (!state?.currentAttestation) return;
            state.currentAttestation.comment = action.payload?.attestAttestation?.comment;
            state.currentAttestation.status = action.payload?.attestAttestation?.status;
        },
        selectedAttestationSet: (state, action: PayloadAction<string>) => {
            state.selectedAttestation = action.payload;
        },
        attestationSet: (state, action) => {
            state.currentAttestation = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAttestations.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getAttestations.fulfilled, (state, action) => {
            state.loading = false;
            state.attestations = action.payload;
        });
        builder.addCase(getAttestation.pending, (state) => {
            state.attestationLoading = true;
        });
        builder.addCase(getAttestation.fulfilled, (state, action) => {
            state.attestationLoading = false;
            state.currentAttestation = action.payload;
        });
        builder.addCase(attest.pending, (state) => {
            state.attestLoading = true;
        });
        builder.addCase(attest.fulfilled, (state) => {
            state.attestLoading = false;
        });
    },
});

// Selectors
export const selectAttestations = (state: RootState) => state.attestation.attestations;
export const selectCurrentAttestation = (state: RootState) => state.attestation.currentAttestation;
export const selectSelectedAttestation = (state: RootState) => state.attestation.selectedAttestation;
export const selectAttestationLoading = (state: RootState) => state.attestation.attestationLoading;
export const selectAttestLoading = (state: RootState) => state.attestation.attestLoading;

export const { currentAttestationSet, selectedAttestationSet, attestationSet } = attestationSlice.actions;

export default attestationSlice.reducer;
