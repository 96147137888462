"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShopAttestationOverviewToJSON = exports.ShopAttestationOverviewFromJSONTyped = exports.ShopAttestationOverviewFromJSON = exports.instanceOfShopAttestationOverview = void 0;
var runtime_1 = require("../runtime");
var CommDate_1 = require("./CommDate");
var ShopAttestationStatus_1 = require("./ShopAttestationStatus");
var ShopAttestationType_1 = require("./ShopAttestationType");
/**
 * Check if a given object implements the ShopAttestationOverview interface.
 */
function instanceOfShopAttestationOverview(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfShopAttestationOverview = instanceOfShopAttestationOverview;
function ShopAttestationOverviewFromJSON(json) {
    return ShopAttestationOverviewFromJSONTyped(json, false);
}
exports.ShopAttestationOverviewFromJSON = ShopAttestationOverviewFromJSON;
function ShopAttestationOverviewFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'requestedBy': !(0, runtime_1.exists)(json, 'requestedBy') ? undefined : json['requestedBy'],
        'dateCreated': !(0, runtime_1.exists)(json, 'dateCreated') ? undefined : (0, CommDate_1.CommDateFromJSON)(json['dateCreated']),
        'status': !(0, runtime_1.exists)(json, 'status') ? undefined : (0, ShopAttestationStatus_1.ShopAttestationStatusFromJSON)(json['status']),
        'type': !(0, runtime_1.exists)(json, 'type') ? undefined : (0, ShopAttestationType_1.ShopAttestationTypeFromJSON)(json['type']),
    };
}
exports.ShopAttestationOverviewFromJSONTyped = ShopAttestationOverviewFromJSONTyped;
function ShopAttestationOverviewToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'requestedBy': value.requestedBy,
        'dateCreated': (0, CommDate_1.CommDateToJSON)(value.dateCreated),
        'status': (0, ShopAttestationStatus_1.ShopAttestationStatusToJSON)(value.status),
        'type': (0, ShopAttestationType_1.ShopAttestationTypeToJSON)(value.type),
    };
}
exports.ShopAttestationOverviewToJSON = ShopAttestationOverviewToJSON;
