"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * NWG.Commerce.Microshop - Api
 * NWG.Commerce.Microshop - Api functionality
 *
 * The version of the OpenAPI document: NWG.Commerce.Microshop - Api
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegisterModelToJSON = exports.RegisterModelFromJSONTyped = exports.RegisterModelFromJSON = exports.instanceOfRegisterModel = void 0;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the RegisterModel interface.
 */
function instanceOfRegisterModel(value) {
    var isInstance = true;
    return isInstance;
}
exports.instanceOfRegisterModel = instanceOfRegisterModel;
function RegisterModelFromJSON(json) {
    return RegisterModelFromJSONTyped(json, false);
}
exports.RegisterModelFromJSON = RegisterModelFromJSON;
function RegisterModelFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'email': !(0, runtime_1.exists)(json, 'email') ? undefined : json['email'],
        'phoneNumber': !(0, runtime_1.exists)(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'firstName': !(0, runtime_1.exists)(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !(0, runtime_1.exists)(json, 'lastName') ? undefined : json['lastName'],
        'password': !(0, runtime_1.exists)(json, 'password') ? undefined : json['password'],
        'confirmPassword': !(0, runtime_1.exists)(json, 'confirmPassword') ? undefined : json['confirmPassword'],
    };
}
exports.RegisterModelFromJSONTyped = RegisterModelFromJSONTyped;
function RegisterModelToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'email': value.email,
        'phoneNumber': value.phoneNumber,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'password': value.password,
        'confirmPassword': value.confirmPassword,
    };
}
exports.RegisterModelToJSON = RegisterModelToJSON;
